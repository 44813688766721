import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, selectCategory } from "../actions/categories";
import {
  Button,
  Fieldset,
  Typography,
  Table,
  StyledDialog,
} from "../components/common";
import classNames from "classnames";

import { EditButton } from "../components/common/icons/HeroIcons";
import CategoryForm from "./CategoryForm";

import { categoriesColumns } from "./TableColumns";

export default function CategoryPage(props) {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const selectedCategory = useSelector(
    (state) => state.categories.selectedCategory
  );

  const [isReset, setIsReset] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <Typography type="h1">List of Categories</Typography>
            <div className="flex justify-end mb-4">
              <Button
                text="+ add category"
                primary="true"
                onClick={() => {
                  dispatch(selectCategory(null));
                  setIsReset(true);
                  setIsEdit(false);
                  setIsOpenModal(!isOpenModal);
                }}
              />
            </div>

            <Table
              columns={categoriesColumns}
              data={
                <>
                  {categories.map((category) => (
                    <tr
                      key={category._id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selectedCategory ? selectedCategory._id : 0) ===
                            category._id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectCategory(category));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <img
                          src={category.photo}
                          alt={`Category - ${category.name}`}
                          style={{ width: 200 }}
                        />
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {category.name}
                          {category.is_hidden && (
                            <div className="text-xs text-gray-900">
                              <span className="m-2 inline-flex items-center justify-center px-4 py-2 mr-2 text-xs text-center font-bold leading-none text-red-100 bg-gray-600 rounded-full">
                                Hidden
                              </span>
                            </div>
                          )}
                        </div>
                      </td>

                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div
                          className="flex flex-col text-gray-600 hover:text-gray-900"
                          onClick={() => {
                            dispatch(selectCategory(category));
                            setIsOpenModal(true);
                            setIsEdit(false);
                          }}
                        >
                          <EditButton title={"Update"} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          </Fieldset>
        </div>
        {isOpenModal && (
          <StyledDialog
            isOpen={isOpenModal}
            setIsOpen={() => setIsOpenModal(!isOpenModal)}
            onClose={() => setIsOpenModal(false)}
            screenWidth={500}
          >
            <CategoryForm
              onViewClose={() => setIsOpenModal(false)}
              isReset={isReset}
              setIsReset={setIsReset}
              isEdit={isEdit}
            />
          </StyledDialog>
        )}
      </div>
    </div>
  );
}
