import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, selectUser } from "../actions/users";
import {
  Button,
  Fieldset,
  Typography,
  Table,
  Modal,
  Spinner,
  Pagination,
  Label,
} from "../components/common";
import useDebounce from "../helpers/useDebounce";
import classNames from "classnames";

import { EditButton } from "../components/common/icons/HeroIcons";
import { usersColumns } from "./TableColumns";
import UserForm from "./UserForm";
import Select from "react-select";
import moment from "moment";

export default function UserPage() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const pagination = useSelector((state) => state.users.pagination);
  const statusOptions = [
    { value: "", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Disabled", label: "Disabled" },
  ];
  const isLoading = useSelector((state) => state.users.isLoading);
  const selectedUser = useSelector((state) => state.users.selectedUser);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "All",
  });
  const [addModal, setAddModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(getUsers(debouncedSearchTerm, pageNumber, selectedStatus.value));
  }, [dispatch, debouncedSearchTerm, pageNumber, selectedStatus]);

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <h1 className="text-sm font-extrabold text-gray-600 mb-4">
              Filter Users
            </h1>

            <div className="flex">
              <div className="w-3/4">
                <Label name="Name" />
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPageNumber(1);
                  }}
                  type="text"
                  placeholder="Search User's Name..."
                />
              </div>

              <div className="w-1/4 px-4">
                <Label name="Status" />
                <Select
                  className="text-xs shadow-sm"
                  options={statusOptions}
                  value={selectedStatus}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <Typography type="h1">List of Users</Typography>
            <div className="flex justify-end mb-4">
              <Button
                text="+ add user"
                primary="true"
                onClick={() => {
                  dispatch(selectUser(null));
                  setAddModal(true);
                }}
              />
            </div>

            {isLoading && <Spinner />}

            {!isLoading && (
              <Table
                columns={usersColumns}
                data={
                  <>
                    {users.map((user) => (
                      <tr
                        key={user._id}
                        className={classNames(
                          "hover:bg-gray-200 cursor-pointer",
                          {
                            "text-blue-600 bg-teal-200":
                              (selectedUser ? selectedUser._id : 0) ===
                              user._id,
                          }
                        )}
                        onClick={() => {
                          dispatch(selectUser(user));
                        }}
                      >
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {user.status === "Pending" && (
                              <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-orange-600 rounded-full">
                                Pending
                              </span>
                            )}

                            {user.status === "Approved" && (
                              <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                                Approved
                              </span>
                            )}

                            {user.status === "Disabled" && (
                              <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                Disabled
                              </span>
                            )}
                          </div>
                        </td>

                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {user.name}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {user.email}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {user.phone_number}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {moment(user.date).format("DD/MM/Y").toString()}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {moment(user.date)
                              .add(1, "year")
                              .format("DD/MM/Y")
                              .toString()}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {user.birth_date
                              ? moment(user.birth_date)
                                  .format("DD/MM/Y")
                                  .toString()
                              : ""}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {user.zodiac_sign}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectUser(user));
                              setAddModal(true);
                            }}
                          >
                            <EditButton title={"Update"} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            )}

            {pagination && !isLoading && (
              <Pagination
                from={pagination.from}
                to={pagination.to}
                total={pagination.total}
                lastPage={pagination.last_page}
                currentPage={pagination.current_page}
                onPageClick={(page) => {
                  setPageNumber(page);
                }}
              />
            )}
          </Fieldset>
        </div>

        {addModal && (
          <Modal>
            <UserForm
              onViewClose={() => {
                setAddModal(false);
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
}
