import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, Table } from "../components/common";
import ImageUtils from "../helpers/ImageUtils";
import ImageUploader from "react-images-upload";
import {
  addPartner,
  updatePartner,
  deletePartner,
  updatePartnerOutlet,
  addPartnerOutlet,
  deletePartnerOutlet,
  selectPartner,
} from "../actions/partners";
import { getCategories } from "../actions/categories";
import { useTransformOption } from "../helpers/useTransformData";
import { partnerOutletColumns } from "./TableColumns";
import Select from "react-select";
import swal from "sweetalert";

export default function PartnerForm({ onViewClose }) {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const categoriesOption = useTransformOption(categories, "_id", "name");
  const [selectedCategories, setSelectedCategories] = useState(null);

  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner
  );

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [photo, setPhoto] = useState([]);
  const [outlets, setOutlets] = useState([]);

  //Outlet fields
  const [outletName, setOutletName] = useState(null);
  const [outletAddress, setOutletAddress] = useState(null);
  const [outletPhone, setOutletPhone] = useState(null);
  const [outletPin, setOutletPin] = useState(null);

  const imageUploadRef = useRef(null);
  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const handleCategoriesChange = (e) => {
    setSelectedCategories(
      Array.isArray(e)
        ? e.map((x) => {
            return { value: x.value, label: x.label };
          })
        : []
    );
  };

  const addOutlet = () => {
    let outlet = {
      id: outlets.length + 1,
      name: outletName,
      address: outletAddress,
      phone: outletPhone,
      pin: outletPin,
    };

    setOutlets([...outlets, outlet]);

    setOutletName("");
    setOutletAddress("");
    setOutletPhone("");
    setOutletPin("");
  };

  const handleOutletChange = (index, event) => {
    const values = [...outlets];
    values[index][event.target.name] = event.target.value;

    setOutlets(values);
  };

  const removeOutlet = (outlet) => {
    setOutlets(outlets.filter((out) => out.id !== outlet.id));
  };

  const saveOutlet = () => {
    swal({
      icon: "warning",
      title: "Add Outlet?",
      text: "This will add the outlet immediately to this partner.",
      buttons: true,
    }).then((willAdd) => {
      if (willAdd) {
        let outlet = {
          name: outletName,
          address: outletAddress,
          phone: outletPhone,
          pin: outletPin,
        };
        dispatch(addPartnerOutlet(outlet, selectedPartner._id));
      }
    });
  };

  const removeSavedOutlet = (outlet) => {
    swal({
      icon: "error",
      title: "Remove Saved Outlet?",
      text: "This will remove the saved outlet from this partner. This action is irreversible.",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deletePartnerOutlet(selectedPartner._id, outlet._id));
      }
    });
  };

  const updateSavedOutlet = (outlet) => {
    swal({
      icon: "warning",
      title: "Update Saved Outlet?",
      text: "This will update the saved outlet from this partner.",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        dispatch(updatePartnerOutlet(outlet, selectedPartner._id, outlet._id));
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will ${
        selectedPartner ? "update" : "add to"
      } the list of partners.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let tempImg = null;
        let data = {
          name: name,
          description: description,
          categories: Array.isArray(selectedCategories)
            ? selectedCategories.map((c) => c.value)
            : [],
        };
        console.log(data);
        if (!selectedPartner) {
          data.outlets = outlets;
        }

        for (let i = 0; i < photo.length; i++) {
          tempImg = await ImageUtils.resizeImage(photo[i]);
        }

        if (tempImg) {
          data.photo = tempImg;
        }

        if (selectedPartner) {
          dispatch(updatePartner(data, selectedPartner._id));
        } else {
          dispatch(addPartner(data));
        }
        onViewClose && onViewClose();
      }
    });
  };

  const onToggleVisibility = async (e) => {
    e.preventDefault();

    console.log(!selectedPartner.is_hidden);

    let data = {
      is_hidden: !selectedPartner.is_hidden,
    };

    dispatch(updatePartner(data, selectedPartner._id));
  };

  const removePartner = (e) => {
    e.preventDefault();
    swal({
      icon: "error",
      title: "Are you sure?",
      text: `This will remove the partner from the list. This action is irreversible.`,
      buttons: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(deletePartner(selectedPartner._id));
        dispatch(selectPartner(null));
        onViewClose && onViewClose();
      }
    });
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPartner) {
      setName(selectedPartner.name);
      setDescription(selectedPartner.description);
      //Loop through categories
      if (selectedPartner.categories !== undefined) {
        if (selectedPartner.categories.length > 0) {
          let categories = [];
          for (let c in selectedPartner.categories) {
            categories.push({
              label: selectedPartner.categories[c].name,
              value: selectedPartner.categories[c]._id,
            });
          }
          setSelectedCategories(categories);
          console.log(categories);
        } else {
          setSelectedCategories([]);
        }
      } else {
        setSelectedCategories([]);
      }
      setOutlets(selectedPartner.outlets);
    }
  }, [selectedPartner]);

  return (
    <form>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedPartner ? "Update" : "Add"} Partner
      </h1>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Select Categories" />
          <Select
            isMulti
            isClearable
            className="text-xs shadow-sm"
            options={categoriesOption}
            onChange={handleCategoriesChange}
            value={selectedCategories}
          />
        </div>
        <div className="w-1/2 px-4">
          <Label name="Name" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Description" />
          <textarea
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            value={description}
            rows={5}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="w-1/2 px-4">
          <Label name="Photo" />
          {selectedPartner && (
            <p className="text-gray-600 text-xs">
              Current photo saved. Upload only if you want to change the photo.
            </p>
          )}
          <p className="text-xs text-gray-500 mb-4">
            Recommended dimensions: 500px x 500px
          </p>
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            singleImage={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>
      <div className="grid p-4 mt-4">
        <p className="text-sm font-bold text-gray-600 mb-4">Outlets</p>
        <div className="flex mb-4">
          <div className="w-1/4">
            <Label name="Outlet Name" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="outlet_name"
              type="text"
              value={outletName}
              onChange={(e) => setOutletName(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Address" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="outlet_address"
              type="text"
              value={outletAddress}
              onChange={(e) => setOutletAddress(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Phone" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="outlet_phone"
              type="text"
              value={outletPhone}
              onChange={(e) => setOutletPhone(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Phone" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="outlet_pin"
              type="number"
              value={outletPin}
              onChange={(e) => setOutletPin(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Button
              text={`+ Add Outlet`}
              primary="true"
              onClick={() => {
                if (selectedPartner) {
                  saveOutlet();
                } else {
                  addOutlet();
                }
              }}
            />
          </div>
        </div>

        <Table
          columns={partnerOutletColumns}
          data={
            <>
              {outlets.map((outlet, index) => (
                <tr key={index} className="hover:bg-blue-200">
                  <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                    {selectedPartner ? (
                      <>
                        <input
                          className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          autoComplete="off"
                          name="name"
                          type="text"
                          value={outlet.name}
                          onChange={(e) => {
                            handleOutletChange(index, e);
                          }}
                        />
                      </>
                    ) : (
                      outlet.name
                    )}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                    {selectedPartner ? (
                      <>
                        <input
                          className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          autoComplete="off"
                          name="address"
                          type="text"
                          value={outlet.address}
                          onChange={(e) => {
                            handleOutletChange(index, e);
                          }}
                        />
                      </>
                    ) : (
                      outlet.address
                    )}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                    {selectedPartner ? (
                      <>
                        <input
                          className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          autoComplete="off"
                          name="phone"
                          type="text"
                          value={outlet.phone}
                          onChange={(e) => {
                            handleOutletChange(index, e);
                          }}
                        />
                      </>
                    ) : (
                      outlet.phone
                    )}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                    {selectedPartner ? (
                      <>
                        <input
                          className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          autoComplete="off"
                          name="pin"
                          type="text"
                          value={outlet.pin}
                          onChange={(e) => {
                            handleOutletChange(index, e);
                          }}
                        />
                      </>
                    ) : (
                      outlet.pin
                    )}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        if (selectedPartner) {
                          removeSavedOutlet(outlet);
                        } else {
                          removeOutlet(outlet);
                        }
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>

                    {selectedPartner && (
                      <button
                        type="button"
                        className="bg-orange-500 hover:bg-orange-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => {
                          updateSavedOutlet(outlet);
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </>
          }
        />
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text={selectedPartner ? "Update" : "Save"}
          success="true"
          onClick={(e) => {
            onSubmit(e);
          }}
        />
        {selectedPartner && (
          <Button
            text={selectedPartner.is_hidden ? "Unhide" : "Hide"}
            warning="true"
            onClick={(e) => {
              swal({
                icon: "warning",
                title: "Are you sure?",
                text: `${
                  selectedPartner.is_hidden ? "Unhide" : "Hide"
                } this partner?`,
                buttons: true,
                dangerMode: true,
              }).then((willUpdate) => {
                if (willUpdate) {
                  onToggleVisibility(e);
                }
              });
            }}
          />
        )}
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            onViewClose && onViewClose();
          }}
        />
      </div>
    </form>
  );
}
