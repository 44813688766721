export const categoriesColumns = [
  {
    name: "Photo",
  },
  {
    name: "Name",
  },
];

export const bannersColumns = [
  {
    name: "Photo",
  },
  {
    name: "Order",
  },
];

export const partnerOutletColumns = [
  {
    name: "Outlet Name",
  },
  {
    name: "Address",
  },
  {
    name: "Phone",
  },
  {
    name: "Default PIN",
  },
];

export const partnersColumns = [
  {
    name: "Photo",
  },
  {
    name: "Category",
  },
  {
    name: "Name",
  },
  {
    name: "Description",
  },
  {
    name: "Outlets",
  },
];

export const usersColumns = [
  {
    name: "Status",
  },
  {
    name: "Name",
  },
  {
    name: "E-mail Address",
  },
  {
    name: "Phone Number",
  },
  {
    name: "Sign-up Date",
  },
  {
    name: "Date of Expiry",
  },
  {
    name: "Birth Date",
  },
  {
    name: "Zodiac Sign",
  },
];

export const vouchersColumns = [
  {
    name: "Photo",
  },
  {
    name: "Name",
  },
  {
    name: "Description",
  },
  {
    name: "Terms and Conditions",
  },
  {
    name: "Validity",
  },
];

export const pinColumns = [
  {
    name: "Label",
  },
  {
    name: "PIN",
  },
];

export const userVoucherColumns = [
  {
    name: "Status",
  },
  {
    name: "Name",
  },
  {
    name: "E-mail Address",
  },
  {
    name: "Phone Number",
  },
  {
    name: "Birth Date",
  },
];

export const voucherDistributionPendingColumns = [
  {
    name: "Name",
  },
  {
    name: "Personal Details",
  },
  {
    name: "Voucher",
  },
  {
    name: "Validity",
  },
];

export const voucherDistributionRedeemedColumns = [
  {
    name: "Date Redeemed",
  },
  {
    name: "Name",
  },
  {
    name: "Personal Details",
  },
  {
    name: "Voucher",
  },
  {
    name: "Validity",
  },
];
