import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBanners, selectBanner } from "../actions/banners";
import {
  Button,
  Fieldset,
  Typography,
  Table,
  StyledDialog,
  Spinner,
} from "../components/common";
import classNames from "classnames";

import { EditButton } from "../components/common/icons/HeroIcons";
import BannerForm from "./BannerForm";

import { bannersColumns } from "./TableColumns";

export default function BannerPage() {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banners.banners);
  const isLoading = useSelector((state) => state.banners.isLoading);
  const selectedBanner = useSelector((state) => state.banners.selectedBanner);

  const [isReset, setIsReset] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <Typography type="h1">List of Sliding Banner Images</Typography>
            <div className="flex justify-end mb-4">
              <Button
                text="+ add image banner"
                primary="true"
                onClick={() => {
                  dispatch(selectBanner(null));
                  setIsReset(true);
                  setIsEdit(false);
                  setIsOpenModal(!isOpenModal);
                }}
              />
            </div>

            {isLoading && <Spinner />}

            {!isLoading && (
              <Table
                columns={bannersColumns}
                data={
                  <>
                    {banners.map((banner) => (
                      <tr
                        key={banner._id}
                        className={classNames(
                          "hover:bg-gray-200 cursor-pointer",
                          {
                            "text-blue-600 bg-teal-200":
                              (selectedBanner ? selectedBanner._id : 0) ===
                              banner._id,
                          }
                        )}
                        onClick={() => {
                          dispatch(selectBanner(banner));
                        }}
                      >
                        <td className="px-6 py-3 whitespace-nowrap">
                          <img
                            src={banner.photo}
                            alt={`Banner # ${banner.order}`}
                            style={{ width: 200 }}
                          />
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {banner.order}
                          </div>
                        </td>

                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectBanner(banner));
                              setIsOpenModal(true);
                              setIsEdit(false);
                            }}
                          >
                            <EditButton title={"Update"} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            )}
          </Fieldset>
        </div>
        {isOpenModal && (
          <StyledDialog
            isOpen={isOpenModal}
            setIsOpen={() => setIsOpenModal(!isOpenModal)}
            onClose={() => setIsOpenModal(false)}
            screenWidth={500}
          >
            <BannerForm
              onViewClose={() => setIsOpenModal(false)}
              isReset={isReset}
              setIsReset={setIsReset}
              isEdit={isEdit}
            />
          </StyledDialog>
        )}
      </div>
    </div>
  );
}
