import axios from "axios";
import {
  LOADING_VOUCHERS,
  GET_VOUCHERS,
  SELECT_VOUCHER,
  ADD_VOUCHER,
  ADD_VOUCHER_PIN,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_PIN,
  DELETE_VOUCHER_PIN,
  GET_VOUCHER_AVAILABLE_USERS,
  GET_VOUCHER_ASSIGNED_USERS,
  ADD_USERS_TO_VOUCHER,
  REMOVE_USERS_TO_VOUCHER,
  GET_USER_VOUCHERS,
} from "./types";
import swal from "sweetalert";

// Get all vouchers
export const getVouchers = (search_term, page) => async (dispatch) => {
  dispatch({ type: LOADING_VOUCHERS });
  let endpoint = `/api/vouchers?search=${search_term}&page=${page}`;
  try {
    const res = await axios.get(endpoint);

    let pagination =
      res.data.data.length > 0
        ? {
            from: res.data.from,
            to: res.data.data.length,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          }
        : null;

    dispatch({
      type: GET_VOUCHERS,
      payload: res.data.data,
      pagination,
    });
  } catch (err) {
    console.error(err);
  }
};

// Search vouchers (for AsyncSelect)
export const searchVouchers = (search_term) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/api/vouchers?unpaginate=true&search=${search_term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

// Add voucher
export const addVoucher = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/vouchers", data);

    dispatch({
      type: ADD_VOUCHER,
      payload: res.data,
    });
    swal({
      title: "Voucher Added",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Update voucher
export const updateVoucher = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/vouchers/${id}`, data);

    dispatch({
      type: UPDATE_VOUCHER,
      payload: res.data,
    });
    swal({
      title: "Partner Updated",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Add voucher PIN
export const addVoucherPin = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/vouchers/${id}/pin`, data);

    dispatch({
      type: ADD_VOUCHER_PIN,
      payload: res.data,
    });
    swal({
      title: "PIN Added",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Update Voucher PIN
export const updateVoucherPin =
  (data, voucherId, pinId) => async (dispatch) => {
    try {
      const res = await axios.put(
        `/api/vouchers/${voucherId}/pin/${pinId}`,
        data
      );

      dispatch({
        type: UPDATE_VOUCHER_PIN,
        payload: res.data,
      });
      swal({
        title: "PIN Updated",
        icon: "success",
      });
    } catch (err) {
      console.error(err);
    }
  };

// Delete Voucher PIN
export const deleteVoucherPin = (voucherId, pinId) => async (dispatch) => {
  try {
    await axios.delete(`/api/vouchers/${voucherId}/pin/${pinId}`);

    dispatch({
      type: DELETE_VOUCHER_PIN,
      payload: {
        voucher_id: voucherId,
        pin_id: pinId,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

// Select voucher
export const selectVoucher = (voucher) => (dispatch) => {
  dispatch({
    type: SELECT_VOUCHER,
    payload: voucher,
  });
};

// Get voucher users for pending claim
export const getPendingVouchers = (page, voucher, user) => async (dispatch) => {
  dispatch({ type: LOADING_VOUCHERS });
  try {
    let endpoint = "/api/vouchers/users/pending-redeem?";
    if (page) endpoint += `page=${page}&`;
    if (voucher) endpoint += `voucher=${voucher}&`;
    if (user) endpoint += `user=${user}&`;

    const res = await axios.get(endpoint);

    let pagination =
      res.data.data.length > 0
        ? {
            from: res.data.from,
            to: res.data.data.length,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          }
        : null;

    dispatch({
      type: GET_USER_VOUCHERS,
      payload: res.data.data,
      pagination,
    });
  } catch (err) {
    console.error(err);
  }
};

// Get redeemed vouchers
export const getRedeemedVouchers =
  (page, voucher, user) => async (dispatch) => {
    dispatch({ type: LOADING_VOUCHERS });
    try {
      let endpoint = "/api/vouchers/users/claimed?";
      if (page) endpoint += `page=${page}&`;
      if (voucher) endpoint += `voucher=${voucher}&`;
      if (user) endpoint += `user=${user}&`;

      const res = await axios.get(endpoint);

      let pagination =
        res.data.data.length > 0
          ? {
              from: res.data.from,
              to: res.data.data.length,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            }
          : null;

      dispatch({
        type: GET_USER_VOUCHERS,
        payload: res.data.data,
        pagination,
      });
    } catch (err) {
      console.error(err);
    }
  };

// Get available users to assign to voucher
export const getAvailableUsers =
  (voucherId, page, search_term) => async (dispatch) => {
    dispatch({ type: LOADING_VOUCHERS });
    try {
      let endpoint = `/api/vouchers/${voucherId}/available-users?`;
      if (page) endpoint += `page=${page}&`;
      if (search_term) endpoint += `search=${search_term}&`;

      const res = await axios.get(endpoint);

      let pagination =
        res.data.data.length > 0
          ? {
              from: res.data.from,
              to: res.data.data.length,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            }
          : null;

      dispatch({
        type: GET_VOUCHER_AVAILABLE_USERS,
        payload: res.data.data,
        pagination,
      });
    } catch (err) {
      console.error(err);
    }
  };

// Get assigned users from the voucher
export const getAssignedUsers =
  (voucherId, page, search_term) => async (dispatch) => {
    dispatch({ type: LOADING_VOUCHERS });
    try {
      let endpoint = `/api/vouchers/${voucherId}/users?`;
      if (page) endpoint += `page=${page}&`;
      if (search_term) endpoint += `search=${search_term}&`;

      const res = await axios.get(endpoint);

      let pagination =
        res.data.data.length > 0
          ? {
              from: res.data.from,
              to: res.data.data.length,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            }
          : null;

      dispatch({
        type: GET_VOUCHER_ASSIGNED_USERS,
        payload: res.data.data,
        pagination,
      });
    } catch (err) {
      console.error(err);
    }
  };

export const assignUsersToVoucher = (voucherId, users) => async (dispatch) => {
  //users is an array of ObjectIds of users.
  try {
    const res = await axios.put(`/api/vouchers/${voucherId}/user`, { users });
    dispatch({
      type: ADD_USERS_TO_VOUCHER,
      payload: res.data,
    });
    // swal({
    //   title: "Users added to the voucher",
    //   text: "Users can now see the voucher in the mobile app.",
    //   icon: "success",
    // });
  } catch (err) {
    console.error(err);
  }
};

export const removeUsersToVoucher = (voucherId, users) => async (dispatch) => {
  //users is an array of ObjectIds of users.
  try {
    await axios.delete(`/api/vouchers/${voucherId}/user`, {
      data: { users },
    });
    dispatch({
      type: REMOVE_USERS_TO_VOUCHER,
      payload: users,
    });
    swal({
      title: "Users removed to the voucher",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};
