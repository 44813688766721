import React from "react";

function Label({ name }) {
  return (
    <label
      className="block text-gray-700 text-xs font-bold mb-2"
      htmlFor="username"
    >
      {name}
    </label>
  );
}

export default Label;
