import {
  GET_BANNERS,
  LOADING_BANNERS,
  SELECT_BANNER,
  UPDATE_BANNER,
  ADD_BANNER,
  DELETE_BANNER,
} from "../actions/types";

const initialState = {
  banners: [],
  isLoading: false,
  selectedBanner: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_BANNERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BANNERS:
      return {
        ...state,
        isLoading: false,
        banners: payload,
      };
    case ADD_BANNER:
      return {
        ...state,
        isLoading: false,
        banners: [...state.banners, payload],
      };
    case UPDATE_BANNER:
      return {
        ...state,
        banners: state.banners.map((banner) => {
          if (payload._id !== banner._id) {
            return banner;
          }

          return {
            ...banner,
            ...payload,
          };
        }),
      };
    case SELECT_BANNER:
      return {
        ...state,
        selectedBanner: payload,
      };
    case DELETE_BANNER:
      return {
        ...state,
        banners: state.banners.filter((banner) => banner._id !== payload),
      };
    default:
      return state;
  }
}
