import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSiteContent, getSiteContent } from "../actions/content";
import {
  Button,
  Fieldset,
  Typography,
  Label,
  Spinner,
} from "../components/common";
import swal from "sweetalert";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default function ContentPage() {
  const dispatch = useDispatch();
  const siteContent = useSelector((state) => state.content.siteContent);
  const isLoading = useSelector((state) => state.content.isLoading);

  const [faq, setFaq] = useState("");
  const [contactUs, setContactUs] = useState("");
  const [businessPartnership, setBusinessPartnership] = useState("");

  const faqRef = useRef();
  const contactUsRef = useRef();
  const businessPartnershipRef = useRef();

  useEffect(() => {
    dispatch(getSiteContent());
  }, [dispatch]);

  useEffect(() => {
    if (siteContent) {
      if (siteContent.hasOwnProperty("faq")) setFaq(siteContent.faq);
      if (siteContent.hasOwnProperty("contact_us"))
        setContactUs(siteContent.contact_us);
      if (siteContent.hasOwnProperty("business_partnership"))
        setBusinessPartnership(siteContent.business_partnership);
    }
  }, [siteContent]);

  const onSubmit = (e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "This will update the site content.",
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        const content = {
          faq,
          contact_us: contactUs,
          business_partnership: businessPartnership,
        };

        dispatch(updateSiteContent(content));
      }
    });
  };

  const handleFaqChange = (content) => {
    setFaq(content);
  };

  const handleContactUsChange = (content) => {
    setContactUs(content);
  };

  const handleBusinessPartnershipChange = (content) => {
    setBusinessPartnership(content);
  };

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <Typography type="h1">Update Site Content</Typography>
            {isLoading && <Spinner />}

            {!isLoading && (
              <>
                <div className="mb-2">
                  <Label name="FAQ (Frequently Asked Questions)" />
                  <SunEditor
                    defaultValue={faq}
                    key="faq"
                    name="faq"
                    height="100"
                    onChange={handleFaqChange}
                    ref={faqRef}
                  />
                </div>

                <div className="mb-2">
                  <Label name="Contact Us" />
                  <SunEditor
                    defaultValue={contactUs}
                    key="contact_us"
                    name="contact_us"
                    height="100"
                    onChange={handleContactUsChange}
                    ref={contactUsRef}
                  />
                </div>

                <div className="mb-2">
                  <Label name="Business Partnership" />
                  <SunEditor
                    defaultValue={businessPartnership}
                    key="business_partnership"
                    name="business_partnership"
                    height="100"
                    onChange={handleBusinessPartnershipChange}
                    ref={businessPartnershipRef}
                  />
                </div>

                <div className="flex">
                  <Button
                    onClick={(e) => {
                      onSubmit(e);
                    }}
                    success="true"
                    text="Update Site Content"
                  />
                </div>
              </>
            )}
          </Fieldset>
        </div>
      </div>
    </div>
  );
}
