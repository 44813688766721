import { SET_ACTIVE_SIDEBAR_NAV, TOGGLE_SIDEBAR } from "../actions/types";

const initialState = {
  activeSidebarNav: "Dashboard",
  navbarTitle: "Dashboard",
  sidebarVisible: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    case SET_ACTIVE_SIDEBAR_NAV:
      return {
        ...state,
        activeSidebarNav: action.payload,
        navbarTitle: action.payload,
      };
    default:
      return state;
  }
}
