import {
  GET_VOUCHERS,
  SELECT_VOUCHER,
  LOADING_VOUCHERS,
  ADD_VOUCHER,
  ADD_VOUCHER_PIN,
  UPDATE_VOUCHER,
  DELETE_VOUCHER,
  UPDATE_VOUCHER_PIN,
  DELETE_VOUCHER_PIN,
  ADD_USERS_TO_VOUCHER,
  REMOVE_USERS_TO_VOUCHER,
  GET_VOUCHER_AVAILABLE_USERS,
  GET_VOUCHER_ASSIGNED_USERS,
  GET_USER_VOUCHERS,
} from "../actions/types";

const initialState = {
  vouchers: [],
  pagination: null,
  isLoading: false,
  selectedVoucher: null,
  voucherUsers: [],
  voucherUsersPagination: null,
  availableUsers: [],
  availableUsersPagination: null,
  assignedUsers: [],
  assignedUsersPagination: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_VOUCHERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_VOUCHERS:
      return {
        ...state,
        vouchers: payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case SELECT_VOUCHER:
      return {
        ...state,
        selectedVoucher: payload,
      };
    case ADD_VOUCHER:
      return {
        ...state,
        vouchers: [...state.vouchers, payload],
      };
    case UPDATE_VOUCHER:
      return {
        ...state,
        vouchers: state.vouchers.map((voucher) => {
          if (payload._id !== voucher._id) {
            return voucher;
          }

          return {
            ...voucher,
            ...payload,
          };
        }),
      };
    case DELETE_VOUCHER:
      return {
        ...state,
        vouchers: state.vouchers.filter((voucher) => voucher._id !== payload),
      };
    case ADD_VOUCHER_PIN:
      return {
        ...state,
        vouchers: state.vouchers.map((voucher) => {
          if (payload._id !== voucher._id) {
            return voucher;
          }

          return {
            ...voucher,
            ...payload,
          };
        }),
        selectedVoucher: payload,
      };
    case UPDATE_VOUCHER_PIN:
      return {
        ...state,
        vouchers: state.vouchers.map((voucher) => {
          if (payload._id !== voucher._id) {
            return voucher;
          }

          return {
            ...voucher,
            ...payload,
          };
        }),
        selectedVoucher: payload,
      };
    case DELETE_VOUCHER_PIN:
      return {
        ...state,
        vouchers: state.vouchers.map((voucher) => {
          if (payload.voucher_id !== voucher._id) {
            return voucher;
          }

          voucher.pins = voucher.pins.filter(
            (pin) => pin._id !== payload.pin_id
          );

          return {
            ...voucher,
          };
        }),
        selectedVoucher: {
          ...state.selectedVoucher,
          pins: state.pins.filter((pin) => pin._id !== payload.pin_id),
        },
      };
    case GET_USER_VOUCHERS:
      return {
        ...state,
        voucherUsers: payload,
        voucherUsersPagination: action.pagination,
        isLoading: false,
      };
    case GET_VOUCHER_AVAILABLE_USERS:
      return {
        ...state,
        isLoading: false,
        availableUsers: payload,
        availableUsersPagination: action.pagination,
      };
    case GET_VOUCHER_ASSIGNED_USERS:
      return {
        ...state,
        isLoading: false,
        assignedUsers: payload,
        assignedUsersPagination: action.pagination,
      };
    case ADD_USERS_TO_VOUCHER:
      let users = [];
      for (let voucherUser of payload) {
        users.push(voucherUser.user._id);
      }
      let availableUsers = state.availableUsers.filter(
        (availableUser) => !users.includes(availableUser._id)
      );
      //Upon tabbed navigation, the dispatch to get assigned
      //voucher users is called anyway so need to reduce it here.
      return {
        ...state,
        availableUsers,
      };
    case REMOVE_USERS_TO_VOUCHER:
      let assignedUsers = state.assignedUsers.filter(
        (assignedUser) => !payload.includes(assignedUser.user._id)
      );
      //Upon tabbed navigation, the dispatch to get available
      //to assign at a voucher is called anyway so need to reduce it here.
      return {
        ...state,
        assignedUsers,
      };
    default:
      return state;
  }
}
