import {
  GET_PARTNERS,
  SELECT_PARTNER,
  LOADING_PARTNERS,
  ADD_PARTNER,
  ADD_PARTNER_OUTLET,
  UPDATE_PARTNER,
  DELETE_PARTNER,
  UPDATE_PARTNER_OUTLET,
  DELETE_PARTNER_OUTLET,
} from "../actions/types";

const initialState = {
  partners: [],
  pagination: null,
  isLoading: false,
  selectedPartner: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_PARTNERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PARTNERS:
      return {
        ...state,
        partners: payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case SELECT_PARTNER:
      return {
        ...state,
        selectedPartner: payload,
      };
    case ADD_PARTNER:
      return {
        ...state,
        partners: [...state.partners, payload],
      };
    case UPDATE_PARTNER:
      return {
        ...state,
        partners: state.partners.map((partner) => {
          if (payload._id !== partner._id) {
            return partner;
          }

          return {
            ...partner,
            ...payload,
          };
        }),
        selectedPartner: { ...state.selectedPartner, ...payload },
      };
    case DELETE_PARTNER:
      return {
        ...state,
        partners: state.partners.filter((partner) => partner._id !== payload),
      };
    case ADD_PARTNER_OUTLET:
      return {
        ...state,
        partners: state.partners.map((partner) => {
          if (payload._id !== partner._id) {
            return partner;
          }

          return payload;
        }),
        selectedPartner: payload,
      };
    case UPDATE_PARTNER_OUTLET:
      return {
        ...state,
        partners: state.partners.map((partner) => {
          if (payload._id !== partner._id) {
            return partner;
          }

          return payload;
        }),
        selectedPartner: payload,
      };
    case DELETE_PARTNER_OUTLET:
      return {
        ...state,
        partners: state.partners.map((partner) => {
          if (payload._id !== partner._id) {
            return partner;
          }

          partner.outlets = partner.outlets.filter(
            (outlet) => outlet._id !== payload.outlet_id
          );

          return {
            ...partner,
          };
        }),
        selectedPartner: {
          ...state.selectedPartner,
          outlets: state.outlets.filter(
            (outlet) => outlet._id !== payload.pin_id
          ),
        },
      };
    default:
      return state;
  }
}
