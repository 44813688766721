import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fieldset, Typography, CardStats } from "../components/common";
import ChangePasswordForm from "./ChangePasswordForm";
import { countPendingUsers } from "../actions/users";

export default function DashboardPage(props) {
  const dispatch = useDispatch();
  const pendingUsers = useSelector((state) => state.users.pendingUsersCount);

  useEffect(() => {
    dispatch(countPendingUsers());
  }, [dispatch]);

  return (
    <div className="m-12">
      <Fieldset>
        <Typography type="h1">Yoi Administration Dashboard</Typography>
      </Fieldset>
      <div className="flex">
        <div className="w-1/3 px-4">
          <CardStats
            subtitle="Pending Registrations"
            title={pendingUsers.toString()}
            iconName="fas fa-user-clock"
            iconColor="bg-yellow-500"
            description={"Go to Users to process pending users."}
          />
        </div>
        <div className="w-1/3">
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  );
}
