import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label } from "../components/common";
import ImageUtils from "../helpers/ImageUtils";
import ImageUploader from "react-images-upload";
import {
  addBanner,
  updateBanner,
  selectBanner,
  deleteBanner,
} from "../actions/banners";
import swal from "sweetalert";

export default function BannerForm({ setIsReset, isEdit, onViewClose }) {
  const dispatch = useDispatch();

  // state
  const selectedBanner = useSelector((state) => state.banners.selectedBanner);

  const [photo, setPhoto] = useState([]);
  const [order, setOrder] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const imageUploadRef = useRef(null);

  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const onSubmit = async (e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will ${selectedBanner ? "update" : "add"} an Image Banner.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        setSubmitting(true);
        let tempImg = null;

        let data = {
          order: order,
        };
        for (let i = 0; i < photo.length; i++) {
          tempImg = await ImageUtils.resizeImage(photo[i]);
        }
        if (tempImg) {
          data.photo = tempImg;
        }

        if (selectedBanner) {
          dispatch(updateBanner(selectedBanner._id, data));
        } else {
          dispatch(addBanner(data));
        }
      }

      resetFunc();
      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (selectedBanner) {
      setOrder(selectedBanner.order);
    }
  }, [selectedBanner]);

  const resetFunc = async () => {
    setIsReset(false);
    setOrder(null);
    setPhoto(null);
  };

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedBanner
          ? `Image Banner - ${selectedBanner.order}`
          : `Add Image Banner`}
      </h1>

      <div className="mb-2">
        <Label name="Photo" />
        <p className="text-xs text-gray-500 mb-4">
          Recommended dimensions: 450px x 200px
        </p>
        <ImageUploader
          ref={imageUploadRef}
          withPreview={true}
          singleImage={true}
          onChange={onDrop}
          imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
          fileTypeError={" - image not supported"}
          fileSizeError={" - image size too big"}
        />
      </div>

      <div className="mb-2">
        <Label name="Order" />
        <input
          key={selectedBanner ? selectedBanner.order : "order"}
          type="number"
          defaultValue={selectedBanner ? selectedBanner.order : ""}
          className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
          style={{ border: "1px solid #ccc" }}
          autoComplete="off"
          value={order}
          onChange={(e) => {
            setOrder(e.target.value);
          }}
        />
      </div>

      {selectedBanner ? (
        <>
          {!isEdit && (
            <div className="flex">
              <Button
                onClick={(e) => {
                  onSubmit(e);
                }}
                success="true"
                text="Update"
              />
              <Button
                onClick={() => {
                  swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `Delete image banner?`,
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      dispatch(deleteBanner(selectedBanner._id));
                      selectBanner(null);
                    }
                  });
                }}
                warning="true"
                text="Delete"
              />
              <Button
                primary="true"
                text="Close"
                onClick={() => {
                  onViewClose && onViewClose();
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex">
          <Button
            onClick={(e) => {
              onSubmit(e);
            }}
            success="true"
            text="Submit"
            disabled={submitting}
          />

          <Button
            primary="true"
            text="Close"
            onClick={() => {
              onViewClose && onViewClose();
            }}
          />
        </div>
      )}
    </div>
  );
}
