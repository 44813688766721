import axios from "axios";
import {
  LOADING_PARTNERS,
  GET_PARTNERS,
  SELECT_PARTNER,
  ADD_PARTNER,
  ADD_PARTNER_OUTLET,
  UPDATE_PARTNER,
  DELETE_PARTNER,
  UPDATE_PARTNER_OUTLET,
  DELETE_PARTNER_OUTLET,
} from "./types";
import swal from "sweetalert";
// Get all partners
export const getPartners =
  (search_term, page, categories) => async (dispatch) => {
    dispatch({ type: LOADING_PARTNERS });
    let endpoint = `/api/partners/admin?search=${search_term}&page=${page}`;

    if (categories) endpoint += `&categories=${categories}`;
    try {
      const res = await axios.get(endpoint);

      let pagination =
        res.data.data.length > 0
          ? {
              from: res.data.from,
              to: res.data.data.length,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            }
          : null;

      dispatch({
        type: GET_PARTNERS,
        payload: res.data.data,
        pagination,
      });
    } catch (err) {
      console.error(err);
    }
  };

// Search partners (for AsyncSelect)
export const searchPartners = (search_term) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/api/partners/admin?unpaginate=true&search=${search_term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const addPartner = (data) => async (dispatch) => {
  // dispatch({ type: LOADING_PARTNERS });
  try {
    const res = await axios.post("/api/partners", data);

    dispatch({
      type: ADD_PARTNER,
      payload: res.data,
    });
    swal({
      title: "Partner Added",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

export const updatePartner = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/partners/${id}`, data);

    dispatch({
      type: UPDATE_PARTNER,
      payload: res.data,
    });

    swal({
      title: "Partner Updated",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

export const deletePartner = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/partners/${id}`);

    dispatch({
      type: DELETE_PARTNER,
      payload: id,
    });

    swal({
      title: "Partner Deleted",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

export const addPartnerOutlet = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/partners/${id}/outlet`, data);

    dispatch({
      type: ADD_PARTNER_OUTLET,
      payload: res.data,
    });
    dispatch(getPartners());
    swal({
      title: "Outlet Added",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

export const updatePartnerOutlet =
  (data, partnerId, outletId) => async (dispatch) => {
    try {
      const res = await axios.put(
        `/api/partners/${partnerId}/outlet/${outletId}`,
        data
      );

      dispatch({
        type: UPDATE_PARTNER_OUTLET,
        payload: { ...res.data, partner_id: partnerId, outlet_id: outletId },
      });
      // dispatch(getPartners());
      swal({
        title: "Outlet Updated",
        icon: "success",
      });
    } catch (err) {
      console.error(err);
    }
  };

export const deletePartnerOutlet =
  (partnerId, outletId) => async (dispatch) => {
    try {
      await axios.delete(`/api/partners/${partnerId}/outlet/${outletId}`);

      dispatch({
        type: DELETE_PARTNER_OUTLET,
        payload: {
          partner_id: partnerId,
          outlet_id: outletId,
        },
      });
      dispatch(getPartners());
      swal({
        title: "Outlet Deleted",
        icon: "success",
      });
    } catch (err) {
      console.error(err);
    }
  };

// Select partner
export const selectPartner = (partner) => (dispatch) => {
  dispatch({
    type: SELECT_PARTNER,
    payload: partner,
  });
};
