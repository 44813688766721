import axios from "axios";
import {
  GET_BANNERS,
  SELECT_BANNER,
  ADD_BANNER,
  UPDATE_BANNER,
  LOADING_BANNERS,
  DELETE_BANNER,
} from "./types";

import swal from "sweetalert";

// Get all banners
export const getBanners = () => async (dispatch) => {
  dispatch({ type: LOADING_BANNERS });
  try {
    const res = await axios.get("/api/sliding-banners");

    dispatch({
      type: GET_BANNERS,
      payload: res.data,
    });
  } catch (err) {
    console.error(err);
  }
};

// Add banner
export const addBanner = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/sliding-banners", data);

    dispatch({ type: ADD_BANNER, payload: res.data });

    swal({ title: "Banner Added", icon: "success" });
  } catch (err) {
    console.error(err);
  }
};

// Update banner
export const updateBanner = (id, banner) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/sliding-banners/${id}`, banner);

    dispatch({
      type: UPDATE_BANNER,
      payload: res.data,
    });

    swal({
      title: "Banner Updated",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Delete banner
export const deleteBanner = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/sliding-banners/${id}`);

    dispatch({
      type: DELETE_BANNER,
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

// Select banner
export const selectBanner = (banner) => (dispatch) => {
  dispatch({
    type: SELECT_BANNER,
    payload: banner,
  });
};
