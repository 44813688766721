// General
export const SET_ACTIVE_SIDEBAR_NAV = "SET_ACTIVE_SIDEBAR_NAV";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// Users
export const GET_USERS = "GET_USERS";
export const SELECT_USER = "SELECT_USER";
export const LOADING_USERS = "LOADING_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const COUNT_PENDING_USERS = "COUNT_PENDING_USERS";

// Partners
export const GET_PARTNERS = "GET_PARTNERS";
export const SELECT_PARTNER = "SELECT_PARTNER";
export const LOADING_PARTNERS = "LOADING_PARTNERS";
export const ADD_PARTNER = "ADD_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const ADD_PARTNER_OUTLET = "ADD_PARTNER_OUTLET";
export const UPDATE_PARTNER_OUTLET = "UPDATE_PARTNER_OUTLET";
export const DELETE_PARTNER_OUTLET = "DELETE_PARTNER_OUTLET";

// Categories
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// Vouchers
export const GET_VOUCHERS = "GET_VOUCHERS";
export const SELECT_VOUCHER = "SELECT_VOUCHER";
export const LOADING_VOUCHERS = "LOADING_VOUCHERS";
export const ADD_VOUCHER = "ADD_VOUCHER";
export const UPDATE_VOUCHER = "UPDATE_VOUCHER";
export const DELETE_VOUCHER = "DELETE_VOUCHER";
export const ADD_VOUCHER_PIN = "ADD_VOUCHER_PIN";
export const UPDATE_VOUCHER_PIN = "UPDATE_VOUCHER_PIN";
export const DELETE_VOUCHER_PIN = "DELETE_VOUCHER_PIN";
export const ADD_USERS_TO_VOUCHER = "ADD_USERS_TO_VOUCHER";
export const REMOVE_USERS_TO_VOUCHER = "REMOVE_USERS_TO_VOUCHER";
export const GET_VOUCHER_ASSIGNED_USERS = "GET_VOUCHER_ASSIGNED_USERS";
export const GET_USER_VOUCHERS = "GET_USER_VOUCHERS";
export const GET_VOUCHER_AVAILABLE_USERS = "GET_VOUCHER_AVAILABLE_USERS";

// Sliding Banners
export const GET_BANNERS = "GET_BANNERS";
export const SELECT_BANNER = "SELECT_BANNER";
export const LOADING_BANNERS = "LOADING_BANNERS";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const ADD_BANNER = "ADD_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";

// Popup Sliding Banners
export const GET_POPUP_BANNERS = "GET_POPUP_BANNERS";
export const SELECT_POPUP_BANNER = "SELECT_POPUP_BANNER";
export const LOADING_POPUP_BANNERS = "LOADING_POPUP_BANNERS";
export const UPDATE_POPUP_BANNER = "UPDATE_POPUP_BANNER";
export const ADD_POPUP_BANNER = "ADD_POPUP_BANNER";
export const DELETE_POPUP_BANNER = "DELETE_POPUP_BANNER";

// Site Content
export const GET_SITE_CONTENT = "GET_SITE_CONTENT";
export const UPDATE_SITE_CONTENT = "UPDATE_SITE_CONTENT";
export const LOADING_SITE_CONTENT = "LOADING_SITE_CONTENT";
