import api from "../utils/api";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { subscribeUser } from "../subscription";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/web");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });

    //Subscribe to web push notifications
    subscribeUser(res.data._id);
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/users", formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      //   errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/auth/web", body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => toast.warn(error.msg));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Change Password
export const changePassword =
  (email, oldPassword, newPassword) => async (dispatch) => {
    const body = { email, old_password: oldPassword, password: newPassword };

    try {
      await api.post("/auth/change-password", body);

      swal({
        title: "Successfully changed password!",
        icon: "success",
      });
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => toast.warn(error.msg));
      }
    }
  };

// Reset Password
export const resetPassword = (token, password) => async (dispatch) => {
  const body = { token, password };

  try {
    await api.post("/users/reset-password", body);

    swal({
      title: "Password has been reset.",
      text: "You can login again to Yoi app. You can now close this window.",
      icon: "success",
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => toast.warn(error.msg));
    }
  }
};

// Logout
export const logout = () => ({ type: LOGOUT });
