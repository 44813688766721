import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Fieldset,
  Typography,
  Table,
  Modal,
  Spinner,
  Pagination,
  Label,
  Tab,
} from "../components/common";
import {
  getPendingVouchers,
  getRedeemedVouchers,
  searchVouchers,
} from "../actions/vouchers";
import { searchUsers } from "../actions/users";

import {
  voucherDistributionPendingColumns,
  voucherDistributionRedeemedColumns,
} from "./TableColumns";
import AsyncSelect from "react-select/async";
import moment from "moment";

import VoucherDistributionForm from "./VoucherDistributionForm";

export default function VoucherDistributionPage() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("Pending Redeem");
  const [pageNumber, setPageNumber] = useState(1);
  const voucherUsers = useSelector((state) => state.vouchers.voucherUsers);
  const pagination = useSelector(
    (state) => state.vouchers.voucherUsersPagination
  );
  const isLoading = useSelector((state) => state.vouchers.isLoading);
  const [voucher, setVoucher] = useState(null);
  const [user, setUser] = useState(null);
  const [addModal, setAddModal] = useState(false);

  const loadVoucherOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchVouchers(inputValue)).then((res) => {
        resolve(res);
      });
    });

    let options = [];

    for (let i in search) {
      options.push({
        voucher: search[i],
        value: search[i]["_id"],
        label: `${search[i]["name"]} - (From ${moment(
          search[i]["validity_start_date"]
        )
          .format("DD/MM/Y")
          .toString()} - To ${moment(search[i]["validity_end_date"])
          .format("DD/MM/Y")
          .toString()})`,
      });
    }

    callback(options);
  };

  const loadUserOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchUsers(inputValue)).then((res) => {
        resolve(res);
      });
    });

    let options = [];

    for (let i in search) {
      options.push({
        user: search[i],
        value: search[i]["_id"],
        label: `${search[i]["name"]}`,
      });
    }

    callback(options);
  };

  const handleVoucherOptionChange = (option) => {
    setVoucher(option);
  };

  const handleUserOptionChange = (option) => {
    setUser(option);
  };

  useEffect(() => {
    if (tab === "Pending Redeem") {
      dispatch(
        getPendingVouchers(
          pageNumber,
          voucher ? voucher.value : null,
          user ? user.value : null
        )
      );
    } else {
      dispatch(
        getRedeemedVouchers(
          pageNumber,
          voucher ? voucher.value : null,
          user ? user.value : null
        )
      );
    }
  }, [dispatch, tab, pageNumber, voucher, user]);

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <h1 className="text-sm font-extrabold text-gray-600 mb-4">
              Filter Voucher Distributions
            </h1>
            <div className="flex mb-4">
              <div className="w-1/2">
                <Label name="Search Voucher" />
                <AsyncSelect
                  loadOptions={loadVoucherOptions}
                  value={voucher}
                  onChange={handleVoucherOptionChange}
                  className="text-xs shadow-sm"
                  isClearable={true}
                />
              </div>
              <div className="w-1/2 px-4">
                <Label name="Search User" />
                <AsyncSelect
                  loadOptions={loadUserOptions}
                  value={user}
                  onChange={handleUserOptionChange}
                  className="text-xs shadow-sm"
                  isClearable={true}
                />
              </div>
            </div>
          </Fieldset>
        </div>
      </div>

      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <Typography type="h1">List of Voucher Distributions</Typography>
            <div className="mb-4">
              <Tab>
                <Tab.Item
                  name="Pending Redeem"
                  isActive={tab === "Pending Redeem"}
                  onTabClick={() => {
                    setTab("Pending Redeem");
                  }}
                />

                <Tab.Item
                  name="Claimed"
                  isActive={tab === "Claimed"}
                  onTabClick={() => {
                    setTab("Claimed");
                  }}
                />
              </Tab>
              <div className="flex justify-end mb-4">
                <Button
                  text="+ distribute voucher"
                  onClick={() => {
                    setAddModal(true);
                  }}
                />
              </div>
            </div>

            {isLoading && <Spinner />}

            {!isLoading && (
              <Table
                columns={
                  tab === "Pending Redeem"
                    ? voucherDistributionPendingColumns
                    : voucherDistributionRedeemedColumns
                }
                data={
                  <>
                    {voucherUsers.map((voucherUser) => (
                      <tr
                        key={voucherUser._id}
                        className="hover:bg-gray-200 cursor-pointer"
                      >
                        {tab === "Claimed" && (
                          <td className="px-6 py-2 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {moment(voucherUser.date_redeemed)
                                .format("DD/MM/Y")
                                .toString()}
                            </div>
                          </td>
                        )}
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {voucherUser.user.name}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {voucherUser.user.email}
                            <br />
                            {voucherUser.user.phone_number}
                            <br />
                            {moment(voucherUser.user.birth_date)
                              .format("DD/MM/Y")
                              .toString()}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            <img
                              src={voucherUser.voucher.photo}
                              alt={`Voucher - ${voucherUser.voucher.name}`}
                              style={{ width: 100 }}
                            />
                            <br />
                            {voucherUser.voucher.name}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            From{" "}
                            {moment(voucherUser.voucher.validity_start_date)
                              .format("DD/MM/Y")
                              .toString()}{" "}
                            to{" "}
                            {moment(voucherUser.voucher.validity_end_date)
                              .format("DD/MM/Y")
                              .toString()}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            )}

            {pagination && !isLoading && (
              <Pagination
                from={pagination.from}
                to={pagination.to}
                total={pagination.total}
                lastPage={pagination.last_page}
                currentPage={pagination.current_page}
                onPageClick={(page) => {
                  setPageNumber(page);
                }}
              />
            )}
          </Fieldset>
        </div>

        {addModal && (
          <Modal>
            <VoucherDistributionForm
              onViewClose={() => {
                setAddModal(false);
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
}
