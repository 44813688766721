import React from "react";
import classNames from "classnames";

function Pagination(props) {
  let pages = [];

  for (let page = 1; page <= props.lastPage; page++) {
    pages.push(page);
  }

  return (
    <div className="m-2 flex p-2">
      {props.from && props.to && props.total && (
        <p className="mt-2 text-sm">
          Showing {props.from} to {props.to} of {props.total} entries
        </p>
      )}

      <ul className="ml-4 flex flex-wrap list-reset content-center border border-grey-400 rounded w-auto">
        <li>
          <button
            type="button"
            className={classNames(
              "block border-r border-grey-400 px-3 py-2 text-sm",
              {
                "hover:text-white hover:bg-blue-900 text-blue-900":
                  props.currentPage !== 1,
                "cursor-not-allowed text-gray-600": props.currentPage === 1,
              }
            )}
            disabled={props.currentPage === 1}
            onClick={() => {
              props.onPageClick && props.onPageClick(props.currentPage - 1);
            }}
          >
            Previous
          </button>
        </li>
        {pages.map((page, index) => (
          <li key={index}>
            <button
              type="button"
              className={classNames("block px-3 py-2 text-sm", {
                "hover:text-white hover:bg-blue-900 text-blue-900 border-r border-grey-400":
                  props.currentPage !== page,
                "cursor-not-allowed text-white bg-blue-900 border-r border-blue-400":
                  props.currentPage === page,
              })}
              onClick={() => {
                props.onPageClick && props.onPageClick(page);
              }}
            >
              {page}
            </button>
          </li>
        ))}
        <li>
          <button
            type="button"
            className={classNames(
              "block border-r border-grey-400 px-3 py-2 text-sm",
              {
                "hover:text-white hover:bg-blue-900 text-blue-900":
                  props.currentPage !== props.lastPage,
                "cursor-not-allowed text-gray-600":
                  props.currentPage === props.lastPage,
              }
            )}
            disabled={props.currentPage === props.lastPage}
            onClick={() => {
              props.onPageClick && props.onPageClick(props.currentPage + 1);
            }}
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Pagination;
