import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebarNav } from "../../actions/general";
import yoiLogo from "../../assets/img/yoi-logo.png";
import { logout } from "../../actions/auth";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const activeSidebarNav = useSelector(
    (state) => state.general.activeSidebarNav
  );
  const dispatch = useDispatch();

  return (
    <div>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/"
            onClick={() => {
              dispatch(setActiveSidebarNav("Dashboard"));
            }}
          >
            <img
              src={yoiLogo}
              alt="Yoi Logo"
              style={{ width: 64, height: 64 }}
            />
          </Link>

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                    onClick={() => {
                      dispatch(setActiveSidebarNav("Dashboard"));
                    }}
                  >
                    YOI CARD
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Dashboard"
                      ? "text-orange-500 hover:text-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Dashboard"));
                  }}
                >
                  <i className="fas fa-tools opacity-75 mr-2 text-sm"></i>{" "}
                  Dashboard
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Categories"
                      ? "text-orange-500 hover:text-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/categories"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Categories"));
                  }}
                >
                  <i className="fas fa-tags opacity-75 mr-2 text-sm"></i>{" "}
                  Categories
                </Link>
              </li>
            </ul>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Partners"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/partners"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Partners"));
                  }}
                >
                  <i className="fas fa-handshake opacity-75 mr-2 text-sm"></i>{" "}
                  Partners
                </Link>
              </li>
            </ul>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Vouchers"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/vouchers"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Vouchers"));
                  }}
                >
                  <i className="fas fa-qrcode opacity-75 mr-2 text-sm"></i>{" "}
                  Vouchers
                </Link>
              </li>
            </ul>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Voucher Distributions"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/voucher-distributions"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Voucher Distributions"));
                  }}
                >
                  <i className="fas fa-qrcode opacity-75 mr-2 text-sm"></i>{" "}
                  Voucher Distributions
                </Link>
              </li>
            </ul>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Users"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/users"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Users"));
                  }}
                >
                  <i className="fas fa-users opacity-75 mr-2 text-sm"></i> Users
                </Link>
              </li>
            </ul>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Sliding Banners"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/banners"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Sliding Banners"));
                  }}
                >
                  <i className="fas fa-images opacity-75 mr-2 text-sm"></i>{" "}
                  Sliding Banners
                </Link>
              </li>
            </ul>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Popup Banners"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/popup-banners"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Popup Banners"));
                  }}
                >
                  <i className="fas fa-images opacity-75 mr-2 text-sm"></i>{" "}
                  Popup Banners
                </Link>
              </li>
            </ul>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    activeSidebarNav === "Site Content"
                      ? "text-orange-500 hover:tex1t-orange-600 text-xs uppercase py-3 font-bold block"
                      : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  to="/site-content"
                  onClick={() => {
                    dispatch(setActiveSidebarNav("Site Content"));
                  }}
                >
                  <i className="fas fa-info-circle opacity-75 mr-2 text-sm"></i>{" "}
                  Site Content
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <button
                  className={
                    "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                  }
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  <i className="fas fa-sign-out-alt opacity-75 mr-2 text-sm"></i>{" "}
                  Log Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
