import axios from "axios";
import {
  GET_POPUP_BANNERS,
  SELECT_POPUP_BANNER,
  ADD_POPUP_BANNER,
  UPDATE_POPUP_BANNER,
  DELETE_POPUP_BANNER,
  LOADING_POPUP_BANNERS,
} from "./types";
import swal from "sweetalert";

// Get all popup banners
export const getPopupBanners = () => async (dispatch) => {
  dispatch({ type: LOADING_POPUP_BANNERS });
  try {
    const res = await axios.get("/api/popup-sliding-banners");

    dispatch({
      type: GET_POPUP_BANNERS,
      payload: res.data,
    });
  } catch (err) {
    console.error(err);
  }
};

// Add popup banner
export const addPopupBanner = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/popup-sliding-banners", data);

    dispatch({
      type: ADD_POPUP_BANNER,
      payload: res.data,
    });
  } catch (err) {
    console.error(err);
  }
};

// Update popup banner
export const updatePopupBanner = (id, popupBanner) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/popup-sliding-banners/${id}`,
      popupBanner
    );

    dispatch({
      type: UPDATE_POPUP_BANNER,
      payload: res.data,
    });

    swal({
      title: "Banner Updated",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Delete popup banner
export const deletePopupBanner = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/popup-sliding-banners/${id}`);

    dispatch({
      type: DELETE_POPUP_BANNER,
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

// Select popup banner
export const selectPopupBanner = (popupBanner) => (dispatch) => {
  dispatch({
    type: SELECT_POPUP_BANNER,
    payload: popupBanner,
  });
};
