export const useTransformOption = (options, valueKey, labelKey) => {
  let arr = [];

  for (let i in options) {
    arr.push({
      value: options[i][valueKey],
      label: options[i][labelKey],
    });
  }

  return arr;
};

export const getZodiacSign = (day, month) => {
  const zodiacSigns = {
    "1/20": "Capricorn",
    "2/19": "Aquarius",
    "3/20": "Pisces",
    "4/20": "Aries",
    "5/21": "Taurus",
    "6/21": "Gemini",
    "7/22": "Cancer",
    "8/22": "Leo",
    "9/23": "Virgo",
    "10/23": "Libra",
    "11/22": "Scorpio",
    "12/21": "Sagittarius",
  };

  return zodiacSigns[
    Object.keys(zodiacSigns).find(
      (date) =>
        new Date(1970, month - 1, day) <=
        new Date(1970, date.split("/")[0] - 1, date.split("/")[1])
    )
  ];
};
