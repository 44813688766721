import {
  GET_POPUP_BANNERS,
  LOADING_POPUP_BANNERS,
  SELECT_POPUP_BANNER,
  UPDATE_POPUP_BANNER,
  ADD_POPUP_BANNER,
  DELETE_POPUP_BANNER,
} from "../actions/types";

const initialState = {
  popupBanners: [],
  isLoading: false,
  selectedPopupBanner: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_POPUP_BANNERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_POPUP_BANNERS:
      return {
        ...state,
        isLoading: false,
        popupBanners: payload,
      };
    case ADD_POPUP_BANNER:
      return {
        ...state,
        isLoading: false,
        popupBanners: [...state.popupBanners, payload],
      };
    case UPDATE_POPUP_BANNER:
      return {
        ...state,
        popupBanners: state.popupBanners.map((popupBanner) => {
          if (payload._id !== popupBanner._id) {
            return popupBanner;
          }

          return {
            ...popupBanner,
            ...payload,
          };
        }),
      };
    case SELECT_POPUP_BANNER:
      return {
        ...state,
        selectedPopupBanner: payload,
      };
    case DELETE_POPUP_BANNER:
      return {
        ...state,
        popupBanners: state.popupBanners.filter(
          (popupBanner) => popupBanner._id !== payload
        ),
      };
    default:
      return state;
  }
}
