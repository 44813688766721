import React from "react";
import PropTypes from "prop-types";

export default function CardStats({
  subtitle,
  title,
  description,
  iconName,
  iconColor,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-gray-600 uppercase font-bold text-xs">
                {subtitle}
              </h5>
              <span className="font-semibold text-xl text-gray-700">
                {title}
              </span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                  iconColor
                }
              >
                <i className={iconName}></i>
              </div>
            </div>
          </div>
          <p className="text-sm text-gray-400 mt-4">
            <span className="whitespace-nowrap">{description}</span>
          </p>
        </div>
      </div>
    </>
  );
}

CardStats.defaultProps = {
  subtitle: "Traffic",
  title: "350,897",
  description: "Since last month",
  iconName: "far fa-chart-bar",
  iconColor: "bg-red-500",
};

CardStats.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  iconColor: PropTypes.string,
};
