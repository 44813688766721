import Resizer from "react-image-file-resizer";

let MAX_WIDTH = 500,
  MAX_HEIGHT = 500,
  COMPRESSION = "PNG",
  QUALITY = 70;

const ImageUtils = {
  resizeLogic(width, height, voucherSize, popupBannerSize) {
    let newWidth = width,
      newHeight = height;

    if (voucherSize) {
      MAX_WIDTH = 800;
      MAX_HEIGHT = 400;
    }

    if (popupBannerSize) {
      MAX_WIDTH = 400;
      MAX_HEIGHT = 600;
    }

    if (width > height) {
      if (width > MAX_WIDTH) {
        newHeight *= MAX_WIDTH / width;
        newWidth = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        newWidth *= MAX_HEIGHT / height;
        newHeight = MAX_HEIGHT;
      }
    }

    return {
      width: newWidth,
      height: newHeight,
    };
  },
  async getImageDimensions(file) {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        let img = new Image();
        img.onload = function () {
          resolve({
            height: img.height,
            width: img.width,
          });
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    });
  },
  async resizeImage(file, voucherSize = false, popupBannerSize = false) {
    let newDimensions = null;
    try {
      const { width, height } = await this.getImageDimensions(file);
      newDimensions = this.resizeLogic(
        width,
        height,
        voucherSize,
        popupBannerSize
      );
    } catch (err) {
      console.error(err);
    }

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        newDimensions.width,
        newDimensions.height,
        COMPRESSION,
        QUALITY,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  },
  async fileToBase64(file) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  },
};

export default ImageUtils;
