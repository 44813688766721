import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartners, selectPartner } from "../actions/partners";
import { getCategories } from "../actions/categories";
import {
  Button,
  Fieldset,
  Typography,
  Table,
  Modal,
  Spinner,
  Pagination,
  Label,
} from "../components/common";
import Select from "react-select";
import useDebounce from "../helpers/useDebounce";
import { useTransformOption } from "../helpers/useTransformData";

import classNames from "classnames";

import { EditButton } from "../components/common/icons/HeroIcons";

import { partnersColumns } from "./TableColumns";
import PartnerForm from "./PartnerForm";

export default function PartnerPage() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const categoriesOption = useTransformOption(
    [{ _id: "", name: "All" }, ...categories],
    "_id",
    "name"
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const partners = useSelector((state) => state.partners.partners);
  const pagination = useSelector((state) => state.partners.pagination);
  const isLoading = useSelector((state) => state.partners.isLoading);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner
  );

  const [addModal, setAddModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleCategoriesChange = (e) => {
    setSelectedCategories(Array.isArray(e) ? e.map((x) => x.value) : []);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(getPartners(debouncedSearchTerm, pageNumber, selectedCategories));
  }, [dispatch, debouncedSearchTerm, pageNumber, selectedCategories]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <h1 className="text-sm font-extrabold text-gray-600 mb-4">
              Filter Partners
            </h1>

            <div className="flex">
              <div className="w-3/4">
                <Label name="Search" />
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPageNumber(1);
                  }}
                  type="text"
                  placeholder="Search partner's name or description..."
                />
              </div>

              <div className="w-1/4 px-4">
                <Label name="Categories" />
                <Select
                  isMulti
                  isClearable
                  className="text-xs shadow-sm"
                  options={categoriesOption}
                  onChange={handleCategoriesChange}
                />
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
      <div className="flex">
        <div className="w-full">
          <Fieldset>
            <Typography type="h1">List of Partners</Typography>
            <div className="flex justify-end mb-4">
              <Button
                text="+ add partner"
                primary="true"
                onClick={() => {
                  dispatch(selectPartner(null));
                  setAddModal(true);
                }}
              />
            </div>

            {isLoading && <Spinner />}

            {!isLoading && (
              <Table
                columns={partnersColumns}
                data={
                  <>
                    {partners.map((partner) => (
                      <tr
                        key={partner._id}
                        className={classNames(
                          "hover:bg-gray-200 cursor-pointer",
                          {
                            "text-blue-600 bg-teal-200":
                              (selectedPartner ? selectedPartner._id : 0) ===
                              partner._id,
                          }
                        )}
                        onClick={() => {
                          dispatch(selectPartner(partner));
                        }}
                      >
                        <td className="px-6 py-3 whitespace-nowrap">
                          <img
                            src={partner.photo}
                            alt={`Partner - ${partner.name}`}
                            style={{ width: 200 }}
                          />
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {partner.categories &&
                              partner.categories.map((category, i) => (
                                <span className="m-2 inline-flex items-center justify-center px-4 py-2 mr-2 text-xs text-center font-bold leading-none text-red-100 bg-gray-600 rounded-full">
                                  {category.name}
                                </span>
                              ))}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {partner.name}
                            {partner.is_hidden && (
                              <div className="text-xs text-gray-900">
                                <span className="m-2 inline-flex items-center justify-center px-4 py-2 mr-2 text-xs text-center font-bold leading-none text-red-100 bg-gray-600 rounded-full">
                                  Hidden
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {partner.description}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {partner.outlets.length} outlets
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectPartner(partner));
                              setAddModal(true);
                            }}
                          >
                            <EditButton title={"Update"} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            )}

            {pagination && !isLoading && (
              <Pagination
                from={pagination.from}
                to={pagination.to}
                total={pagination.total}
                lastPage={pagination.last_page}
                currentPage={pagination.current_page}
                onPageClick={(page) => {
                  setPageNumber(page);
                }}
              />
            )}
          </Fieldset>
        </div>

        {addModal && (
          <Modal>
            <PartnerForm
              onViewClose={() => {
                setAddModal(false);
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
}
