import {
  GET_CATEGORIES,
  SELECT_CATEGORY,
  UPDATE_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
} from "../actions/types";

const initialState = {
  categories: [],
  selectedCategory: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload],
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) => {
          if (payload._id !== category._id) {
            return category;
          }

          return {
            ...category,
            ...payload,
          };
        }),
        selectedCategory: { ...state.selectedCategory, ...payload },
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category._id !== payload
        ),
      };
    default:
      return state;
  }
}
