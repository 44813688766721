import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, Table } from "../components/common";
import ImageUtils from "../helpers/ImageUtils";
import ImageUploader from "react-images-upload";
import { addVoucher } from "../actions/vouchers";
import { searchPartners } from "../actions/partners";
import { pinColumns } from "./TableColumns";
import moment from "moment";
import swal from "sweetalert";
import AsyncSelect from "react-select/async";

export default function VoucherClone({ onViewClose }) {
  const dispatch = useDispatch();
  const selectedVoucher = useSelector(
    (state) => state.vouchers.selectedVoucher
  );

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [validityStartDate, setValidityStartDate] = useState(new Date());
  const [validityEndDate, setValidityEndDate] = useState(new Date());
  const [partnerOption, setPartnerOption] = useState(null);

  const [photo, setPhoto] = useState([]);
  const [pins, setPins] = useState([]);

  // Pin fields
  const [pinLabel, setPinLabel] = useState(null);
  const [pin, setPin] = useState(null);

  const imageUploadRef = useRef(null);
  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const addPin = () => {
    let pinData = {
      id: pins.length + 1,
      label: pinLabel,
      pin: pin,
    };

    setPins([...pins, pinData]);

    setPinLabel("");
    setPin("");
  };

  const loadPartnerOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchPartners(inputValue)).then((res) => {
        resolve(res);
      });
    });

    let options = [];

    for (let i in search) {
      options.push({
        partner: search[i],
        value: search[i]["_id"],
        label: `${search[i].name}`,
      });
    }

    callback(options);
  };

  const handlePartnerOptionChange = (option) => {
    setPartnerOption(option);

    // Set PINs based from the partner's outlets.
    if (option.partner.outlets) {
      if (option.partner.outlets.length > 0) {
        let pins = [];
        for (let o in option.partner.outlets) {
          if (option.partner.outlets[o]["pin"] !== undefined) {
            let pinData = {
              id: pins.length + 1,
              label: option.partner.outlets[o].name,
              pin: option.partner.outlets[o].pin,
            };
            pins.push(pinData);
          }
        }
        setPins(pins);
      }
    }
  };

  const handleValidityStartDateChange = (e) => {
    setValidityStartDate(e.target.value);
  };

  const handleValidityEndDateChange = (e) => {
    setValidityEndDate(e.target.value);
  };

  const handlePinChange = (index, event) => {
    const values = [...pins];
    values[index][event.target.name] = event.target.value;

    setPins(values);
  };

  const removePin = (pin) => {
    setPins(pins.filter((p) => p.id !== pin.id));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add to the list of vouchers.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let tempImg = null;
        let data = {
          name: name,
          description: description,
          terms_and_conditions: termsAndConditions,
          validity_start_date: moment(validityStartDate)
            .format("Y-MM-DD")
            .toString(),
          validity_end_date: moment(validityEndDate)
            .format("Y-MM-DD")
            .toString(),
          pins: pins,
        };

        for (let i = 0; i < photo.length; i++) {
          tempImg = await ImageUtils.resizeImage(photo[i], true);
        }

        if (tempImg) {
          data.photo = tempImg;
        } else {
          data.photo_url = selectedVoucher.photo;
        }
        dispatch(addVoucher(data));
        onViewClose && onViewClose();
      }
    });
  };

  useEffect(() => {
    if (selectedVoucher) {
      setName(selectedVoucher.name);
      setDescription(selectedVoucher.description);
      setValidityStartDate(
        moment(selectedVoucher.validity_start_date).format("Y-MM-DD").toString()
      );
      setValidityEndDate(
        moment(selectedVoucher.validity_end_date).format("Y-MM-DD").toString()
      );
      setTermsAndConditions(selectedVoucher.terms_and_conditions);
      setPins(selectedVoucher.pins);
    }
  }, [selectedVoucher]);

  return (
    <form>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Clone Voucher
      </h1>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Name" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Validity Start Date" />
          <input
            type="date"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={validityStartDate}
            onChange={(e) => {
              handleValidityStartDateChange(e);
            }}
          />
        </div>
        <div className="w-1/2 px-4">
          <Label name="Validity End Date" />
          <input
            type="date"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={validityEndDate}
            onChange={(e) => {
              handleValidityEndDateChange(e);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Description" />
          <textarea
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            value={description}
            rows={5}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Terms and Conditions" />
          <textarea
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            value={termsAndConditions}
            rows={5}
            onChange={(e) => {
              setTermsAndConditions(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Photo" />
          {selectedVoucher && (
            <p className="text-gray-600 text-xs">
              Current photo saved. Upload only if you want to change the photo.
            </p>
          )}
          <p className="text-xs text-gray-500 mb-4">
            Recommended dimensions: 1000px (W) x 450px (H)
          </p>
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            singleImage={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>

      <p className="text-sm font-bold text-gray-600 mb-4">PINs</p>
      <p className="text-xs text-gray-600 mb-4">
        add PINs from assigned partner's outlets
      </p>
      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Search Partner" />
          <AsyncSelect
            loadOptions={loadPartnerOptions}
            value={partnerOption}
            onChange={handlePartnerOptionChange}
            className="text-xs shadow-sm"
          />
        </div>
      </div>

      <p className="text-xs text-gray-600 mb-4">or add below</p>
      <div className="grid p-4 mt-4">
        <div className="flex mb-4">
          <div className="w-1/3">
            <Label name="PIN Label" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="pin_label"
              type="text"
              value={pinLabel}
              onChange={(e) => setPinLabel(e.target.value)}
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="PIN" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="pin"
              type="number"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </div>

          <div className="w-1/3 px-4">
            <Button
              text={`+ Add PIN`}
              primary="true"
              onClick={() => {
                addPin();
              }}
            />
          </div>
        </div>

        <Table
          columns={pinColumns}
          data={
            <>
              {pins.map((p, index) => (
                <tr key={index} className="hover:bg-blue-200">
                  <td className="px-6 py-2 whitespace-nowrap text-xs text-gray-900">
                    <>
                      <input
                        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        autoComplete="off"
                        name="label"
                        type="text"
                        value={p.label}
                        onChange={(e) => {
                          handlePinChange(index, e);
                        }}
                      />
                    </>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-xs text-gray-900">
                    <>
                      <input
                        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        autoComplete="off"
                        name="pin"
                        type="number"
                        value={p.pin}
                        onChange={(e) => {
                          handlePinChange(index, e);
                        }}
                      />
                    </>
                  </td>

                  <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        removePin(p);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </>
          }
        />
      </div>
      <p className="justify-center flex text-xs text-gray-600 mb-4">
        Make sure to change the validity dates before submitting.
      </p>
      <div className="justify-center flex mt-4 px-4">
        <Button
          text={"Save"}
          success="true"
          onClick={(e) => {
            onSubmit(e);
          }}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            onViewClose && onViewClose();
          }}
        />
      </div>
    </form>
  );
}
