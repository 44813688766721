import axios from "axios";
import {
  GET_SITE_CONTENT,
  LOADING_SITE_CONTENT,
  UPDATE_SITE_CONTENT,
} from "./types";

import swal from "sweetalert";

// Get site content
export const getSiteContent = () => async (dispatch) => {
  dispatch({ type: LOADING_SITE_CONTENT });
  try {
    const res = await axios.get("/api/content");

    dispatch({
      type: GET_SITE_CONTENT,
      payload: res.data,
    });
  } catch (err) {
    console.error(err);
  }
};

// Update site content
export const updateSiteContent = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/content", data);

    dispatch({ type: UPDATE_SITE_CONTENT, payload: res.data });

    swal({ title: "Site Content Updated", icon: "success" });
  } catch (err) {
    console.error(err);
  }
};
