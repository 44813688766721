import { combineReducers } from "redux";
import auth from "./auth";
import banners from "./banners";
import categories from "./categories";
import content from "./content";
import general from "./general";
import partners from "./partners";
import popupBanners from "./popupBanners";
import users from "./users";
import vouchers from "./vouchers";

export default combineReducers({
  auth,
  banners,
  categories,
  content,
  general,
  partners,
  popupBanners,
  users,
  vouchers,
});
