import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fieldset, Button, Label } from "../components/common";
import { resetPassword } from "../actions/auth";
import swal from "sweetalert";
import { toast } from "react-toastify";
import yoiLogo from "../assets/img/yoi-logo.png";

export default function ResetUserPassword(props) {
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onSubmit = (e) => {
    if (newPassword === confirmNewPassword) {
      swal({
        icon: "warning",
        title: "Are you sure?",
        text: "This will reset your password and invalidate your e-mail request.",
        buttons: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          const token = props.match.params.token;
          dispatch(resetPassword(token, newPassword));
        }
      });
    } else {
      toast.warn("Passwords do not match.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Fieldset>
        <div className="flex justify-center">
          <img
            src={yoiLogo}
            alt="Yoi Logo"
            style={{ width: 200, height: 200 }}
          />
        </div>
        <h1 className="text-sm font-extrabold text-gray-600">
          Reset Your Password
        </h1>
        <p className="text-xs text-gray-400 mb-4">
          Start gaining access to the app again.
        </p>

        <div className="mb-2">
          <Label name="Set New Password" />
          <input
            type="password"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </div>

        <div className="mb-2">
          <Label name="Confirm New Password" />
          <input
            type="password"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            value={confirmNewPassword}
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
          />
        </div>

        <Button
          onClick={(e) => {
            onSubmit(e);
          }}
          success="true"
          text="Update Password"
        />
      </Fieldset>
    </div>
  );
}
