import axios from "axios";
import {
  GET_CATEGORIES,
  SELECT_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "./types";

import swal from "sweetalert";

// Get all categories
export const getCategories = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/categories");

    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (err) {
    console.error(err);
  }
};

// Add category
export const addCategory = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/categories", data);

    dispatch({
      type: ADD_CATEGORY,
      payload: res.data,
    });

    swal({
      title: "Category Added",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Update category
export const updateCategory = (id, category) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/categories/${id}`, category);

    dispatch({
      type: UPDATE_CATEGORY,
      payload: res.data,
    });

    swal({
      title: "Category Updated",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Delete category
export const deleteCategory = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/categories/${id}`);

    dispatch({
      type: DELETE_CATEGORY,
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

// Select category
export const selectCategory = (category) => (dispatch) => {
  dispatch({
    type: SELECT_CATEGORY,
    payload: category,
  });
};
