import axios from "axios";
import {
  LOADING_USERS,
  GET_USERS,
  SELECT_USER,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  COUNT_PENDING_USERS,
} from "./types";
import swal from "sweetalert";
import { toast } from "react-toastify";

// Get all users
export const getUsers = (search_term, page, status) => async (dispatch) => {
  dispatch({ type: LOADING_USERS });

  let endpoint = `/api/users/admin?search=${search_term}&page=${page}`;

  if (status) endpoint += `&status=${status}`;

  try {
    const res = await axios.get(endpoint);

    let pagination =
      res.data.data.length > 0
        ? {
            from: res.data.from,
            to: res.data.data.length,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          }
        : null;

    dispatch({
      type: GET_USERS,
      payload: res.data.data,
      pagination,
    });
  } catch (err) {
    console.error(err);
  }
};

// Search users (for AsyncSelect)
export const searchUsers = (search_term) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/api/users/admin?unpaginate=true&search=${search_term}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

// Add user
export const addUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/api/users/admin", data);

    dispatch({
      type: ADD_USER,
      payload: res.data,
    });
    swal({
      title: "User Added",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Update user
export const updateUser = (data, id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/users/admin/${id}`, data);

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });

    swal({
      title: "User Updated",
      icon: "success",
    });
  } catch (err) {
    console.error(err);
  }
};

// Count pending users
export const countPendingUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/users/admin/count-pending-users");

    dispatch({
      type: COUNT_PENDING_USERS,
      payload: res.data.count,
    });
  } catch (err) {
    toast.error("Server Error: Cannot get pending users count");
  }
};

// Select user
export const selectUser = (user) => (dispatch) => {
  dispatch({
    type: SELECT_USER,
    payload: user,
  });
};
