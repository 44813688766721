import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import yoiLogo from "../assets/img/yoi-logo.png";
// components
export default function Login() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
          <div className="flex justify-center">
            <img
              src={yoiLogo}
              alt="Yoi Logo"
              style={{ width: 200, height: 200 }}
            />
          </div>
          <h3 className="text-2xl font-bold text-center">
            Login to your account
          </h3>
          <div className="mt-4">
            <div>
              <label className="block" htmlFor="email">
                Email
              </label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
                autoComplete="off"
                className="w-full px-4 py-2 mt-2 border rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
            </div>
            <div className="mt-4">
              <label className="block">Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={onChange}
                placeholder="Password"
                className="w-full px-4 py-2 mt-2 border rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
            </div>
            <div className="flex items-baseline justify-between">
              <button
                className="px-6 py-2 mt-4 w-full text-white bg-blue-600 rounded-lg hover:bg-blue-900"
                type="button"
                onClick={(e) => {
                  onSubmit(e);
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
