import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label } from "../components/common";
import ImageUtils from "../helpers/ImageUtils";
import ImageUploader from "react-images-upload";
import {
  addCategory,
  updateCategory,
  selectCategory,
  deleteCategory,
} from "../actions/categories";
import swal from "sweetalert";

export default function CategoryForm({
  isReset,
  setIsReset,
  isEdit,
  onViewClose,
}) {
  const dispatch = useDispatch();

  // state
  const selectedCategory = useSelector(
    (state) => state.categories.selectedCategory
  );

  const [name, setName] = useState("");
  const [photo, setPhoto] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const imageUploadRef = useRef(null);

  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const onSubmit = async (e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will ${selectedCategory ? "update" : "add"} a Category.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        setSubmitting(true);
        let tempImg = null;

        let data = {
          name: name,
        };
        for (let i = 0; i < photo.length; i++) {
          tempImg = await ImageUtils.resizeImage(photo[i]);
        }
        if (tempImg) {
          data.photo = tempImg;
        }

        if (selectedCategory) {
          dispatch(updateCategory(selectedCategory._id, data));
        } else {
          dispatch(addCategory(data));
        }
      }

      resetFunc();
      setSubmitting(false);
    });
  };

  const onToggleVisibility = async (e) => {
    e.preventDefault();

    let data = {
      is_hidden: !selectedCategory.is_hidden,
    };

    dispatch(updateCategory(selectedCategory._id, data));
  };

  useEffect(() => {
    if (selectedCategory) {
      setName(selectedCategory.name);
    }
  }, [selectedCategory]);

  const resetFunc = async () => {
    setIsReset(false);
    setName(null);
    setPhoto(null);
  };
  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedCategory
          ? `Category - ${selectedCategory.name}`
          : `Add Category`}
      </h1>

      <div className="mb-2">
        <Label name="Name" />
        <input
          key={selectedCategory ? selectedCategory.name : "name"}
          type="text"
          defaultValue={selectedCategory ? selectedCategory.name : ""}
          className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
          style={{ border: "1px solid #ccc" }}
          autoComplete="off"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <div className="mb-2">
        <Label name="Photo" />
        <p className="text-xs text-gray-500 mb-4">
          Recommended dimensions: 500px x 500px
        </p>
        <ImageUploader
          ref={imageUploadRef}
          withPreview={true}
          singleImage={true}
          onChange={onDrop}
          imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
          fileTypeError={" - image not supported"}
          fileSizeError={" - image size too big"}
        />
      </div>

      {selectedCategory ? (
        <>
          {!isEdit && (
            <div className="flex">
              <Button
                onClick={(e) => {
                  onSubmit(e);
                }}
                success="true"
                text="Update"
              />
              <Button
                onClick={(e) => {
                  swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `${
                      selectedCategory.is_hidden ? "Unhide" : "Hide"
                    } this category?`,
                    buttons: true,
                    dangerMode: true,
                  }).then((willUpdate) => {
                    if (willUpdate) {
                      onToggleVisibility(e);
                      //   reset();
                    }
                  });
                }}
                warning="true"
                text={selectedCategory.is_hidden ? "Unhide" : "Hide"}
              />
              <Button
                type="primary"
                primary="true"
                text="Close"
                onClick={() => {
                  onViewClose && onViewClose();
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex">
          <Button
            onClick={(e) => {
              onSubmit(e);
            }}
            success="true"
            text="Submit"
            disabled={submitting}
          />

          <Button
            primary="true"
            text="Close"
            onClick={() => {
              onViewClose && onViewClose();
            }}
          />
        </div>
      )}
    </div>
  );
}
