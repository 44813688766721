import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label } from "../components/common";
import { addUser, updateUser } from "../actions/users";
import Select from "react-select";
import swal from "sweetalert";
import { getZodiacSign } from "../helpers/useTransformData";
import moment from "moment";

export default function UserForm({ onViewClose }) {
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.users.selectedUser);
  const statusOption = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Disabled", label: "Disabled" },
  ];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState({
    label: "Pending",
    value: "Pending",
  });
  const [birthDate, setBirthDate] = useState(null);
  const [zodiacSign, setZodiacSign] = useState("");

  const handleStatusChange = (option) => {
    setStatus(option);
  };

  const handleBirthDateChange = (e) => {
    setBirthDate(e.target.value);
    const dateInput = new Date(e.target.value);
    setZodiacSign(getZodiacSign(dateInput.getDate(), dateInput.getMonth()));
  };

  useEffect(() => {
    if (selectedUser) {
      setName(selectedUser.name);
      setStatus({
        label: selectedUser.status,
        value: selectedUser.status,
      });
      setEmail(selectedUser.email);
      setPhoneNumber(selectedUser.phone_number);
      setBirthDate(
        selectedUser.birth_date
          ? moment(selectedUser.birth_date).format("Y-MM-DD").toString()
          : null
      );
      setZodiacSign(selectedUser.zodiac_sign);
    }
  }, [selectedUser]);

  const onSubmit = async (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will ${
        selectedUser ? "update" : "add to"
      } the list of users.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let data = {
          name: name,
          email: email,
          phone_number: phoneNumber,
          status: status.value,
          birth_date: birthDate
            ? moment(birthDate).format("Y-MM-DD").toString()
            : null,
          zodiac_sign: zodiacSign,
          role: "user",
        };

        if (password) {
          data.password = password;
        }

        if (selectedUser) {
          dispatch(updateUser(data, selectedUser._id));
        } else {
          dispatch(addUser(data));
        }
        onViewClose && onViewClose();
      }
    });
  };

  //   const removeUser = (e) => {
  //       e.preventDefault();
  //       swal({
  //           icon: "error",
  //           title: "Are you sure?",
  //           text: `This will remove the user from the list. This action is irreversible.`,
  //           buttons: true,
  //       }).then(async (willDelete) => {
  //           if (willDelete) {
  //               dispatch(deleteUser)
  //           }
  //       })
  //   }

  return (
    <form>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedUser ? "Update" : "Add"} User
      </h1>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Status" />
          <Select
            className="text-xs shadow-sm"
            options={statusOption}
            value={status}
            onChange={handleStatusChange}
          />
        </div>
        <div className="w-1/2 px-4">
          <Label name="Name" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="E-mail Address" />
          <input
            type="email"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Password" />

          <input
            type="password"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {selectedUser && (
            <p className="text-gray-600 text-xs">
              Current password saved. Type only if will going to change the
              password.
            </p>
          )}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/3">
          <Label name="Phone Number" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>
        <div className="w-1/3 px-4">
          <Label name="Birth Date" />
          <input
            type="date"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={birthDate}
            onChange={(e) => {
              handleBirthDateChange(e);
            }}
          />
        </div>

        <div className="w-1/3 px-4">
          <Label name="Zodiac Sign" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={zodiacSign}
            readOnly
          />
        </div>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text={selectedUser ? "Update" : "Save"}
          success="true"
          onClick={(e) => {
            onSubmit(e);
          }}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            onViewClose && onViewClose();
          }}
        />
      </div>
    </form>
  );
}
