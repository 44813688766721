import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Table,
  Tab,
  Typography,
  Spinner,
  Label,
  Pagination,
} from "../components/common";
import swal from "sweetalert";
import {
  getAvailableUsers,
  getAssignedUsers,
  assignUsersToVoucher,
  removeUsersToVoucher,
  searchVouchers,
} from "../actions/vouchers";
import moment from "moment";
import { usersColumns, userVoucherColumns } from "./TableColumns";
import {
  MinusCircleButton,
  PlusCircleButton,
} from "../components/common/icons/HeroIcons";
import useDebounce from "../helpers/useDebounce";
import AsyncSelect from "react-select/async";

export default function VoucherDistributionForm({ onViewClose }) {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("Assigned");
  const isLoading = useSelector((state) => state.vouchers.isLoading);
  const assignedUsers = useSelector((state) => state.vouchers.assignedUsers);
  const assignedUsersPagination = useSelector(
    (state) => state.vouchers.assignedUsersPagination
  );
  const availableUsers = useSelector((state) => state.vouchers.availableUsers);
  const availableUsersPagination = useSelector(
    (state) => state.vouchers.availableUsersPagination
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [voucher, setVoucher] = useState(null);

  const loadVoucherOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchVouchers(inputValue)).then((res) => {
        resolve(res);
      });
    });

    let options = [];

    for (let i in search) {
      options.push({
        voucher: search[i],
        value: search[i]["_id"],
        label: `${search[i]["name"]} - (From ${moment(
          search[i]["validity_start_date"]
        )
          .format("DD/MM/Y")
          .toString()} - To ${moment(search[i]["validity_end_date"])
          .format("DD/MM/Y")
          .toString()})`,
      });
    }

    callback(options);
  };

  const handleVoucherOptionChange = (option) => {
    setVoucher(option);
  };

  useEffect(() => {
    if (voucher) {
      if (tab === "Assigned") {
        dispatch(
          getAssignedUsers(voucher.value, pageNumber, debouncedSearchTerm)
        );
      } else if (tab === "Available") {
        dispatch(
          getAvailableUsers(voucher.value, pageNumber, debouncedSearchTerm)
        );
      }
    }
  }, [tab, dispatch, voucher, pageNumber, debouncedSearchTerm]);

  return (
    <div>
      <Typography type="h1">Distribute Voucher to Users</Typography>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Search Voucher" />
          <AsyncSelect
            loadOptions={loadVoucherOptions}
            value={voucher}
            onChange={handleVoucherOptionChange}
            className="text-xs"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
            isClearable={true}
          />
        </div>
      </div>

      {voucher && (
        <div className="mb-4">
          <Tab>
            <Tab.Item
              name="Assigned"
              isActive={tab === "Assigned"}
              onTabClick={() => {
                setTab("Assigned");
                setSearchTerm("");
                setPageNumber(1);
              }}
            />

            <Tab.Item
              name="Available"
              isActive={tab === "Available"}
              onTabClick={() => {
                setTab("Available");
                setSearchTerm("");
                setPageNumber(1);
              }}
            />
          </Tab>
        </div>
      )}

      {voucher && (
        <div className="mb-4">
          <Label name="Name" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPageNumber(1);
            }}
            type="text"
            placeholder="Search User's Name..."
          />
        </div>
      )}

      {tab === "Assigned" && voucher && (
        <>
          <Table
            columns={userVoucherColumns}
            data={
              <>
                {assignedUsers.map((assignedUser) => (
                  <tr
                    key={assignedUser._id}
                    className="hover:bg-gray-200 cursor-pointer"
                  >
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {!assignedUser.date_redeemed && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                            Pending Redeem
                          </span>
                        )}

                        {assignedUser.date_redeemed && (
                          <>
                            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                              Claimed
                            </span>
                            <br />
                            {moment(assignedUser.date_redeemed)
                              .format("DD/MM/Y")
                              .toString()}
                            <br />
                            {assignedUser.pin_used[0].pin} (
                            {assignedUser.pin_used[0].label})
                          </>
                        )}
                      </div>
                    </td>

                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {assignedUser.user.name}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {assignedUser.user.email}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {assignedUser.user.phone_number}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(assignedUser.user.birth_date)
                          .format("DD/MM/Y")
                          .toString()}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="flex flex-col text-gray-600 hover:text-gray-900"
                        onClick={() => {
                          swal({
                            title: "Remove user on voucher?",
                            text: "This will remove the assignment of user on the voucher, making the user unable to access the voucher. Are you sure?",
                            icon: "error",
                            buttons: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              dispatch(
                                removeUsersToVoucher(voucher.value, [
                                  assignedUser.user._id,
                                ])
                              );
                            }
                          });
                        }}
                      >
                        <MinusCircleButton title={"Remove"} />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
          {isLoading && <Spinner />}
        </>
      )}

      {tab === "Available" && voucher && (
        <>
          <Table
            columns={usersColumns}
            data={
              <>
                {availableUsers.map((user) => (
                  <tr
                    key={user._id}
                    className="hover:bg-gray-200 cursor-pointer"
                  >
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.status === "Pending" && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                            Pending
                          </span>
                        )}

                        {user.status === "Approved" && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                            Approved
                          </span>
                        )}

                        {user.status === "Disabled" && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                            Disabled
                          </span>
                        )}
                      </div>
                    </td>

                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.name}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.email}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.phone_number}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(user.date).format("DD/MM/Y").toString()}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(user.date)
                          .add(1, "year")
                          .format("DD/MM/Y")
                          .toString()}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(user.birth_date).format("DD/MM/Y").toString()}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.zodiac_sign}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="flex flex-col text-gray-600 hover:text-gray-900"
                        onClick={() => {
                          dispatch(
                            assignUsersToVoucher(voucher.value, [user._id])
                          );
                        }}
                      >
                        <PlusCircleButton title={"Add"} />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </>
      )}

      {tab === "Assigned" && assignedUsersPagination && !isLoading && (
        <Pagination
          from={assignedUsersPagination.from}
          to={assignedUsersPagination.to}
          total={assignedUsersPagination.total}
          lastPage={assignedUsersPagination.last_page}
          currentPage={assignedUsersPagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}

      {tab === "Available" && availableUsersPagination && !isLoading && (
        <Pagination
          from={availableUsersPagination.from}
          to={availableUsersPagination.to}
          total={availableUsersPagination.total}
          lastPage={availableUsersPagination.last_page}
          currentPage={availableUsersPagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            onViewClose && onViewClose();
          }}
        />
      </div>
    </div>
  );
}
