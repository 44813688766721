import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

import Footer from "./Footer";

import DashboardPage from "../../views/DashboardPage";
import CategoryPage from "../../views/CategoryPage";
import PartnerPage from "../../views/PartnerPage";
import UserPage from "../../views/UserPage";
import VoucherPage from "../../views/VoucherPage";
import VoucherDistributionPage from "../../views/VoucherDistributionPage";
import BannerPage from "../../views/BannerPage";
import PopupBannerPage from "../../views/PopupBannerPage";
import ContentPage from "../../views/ContentPage";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import classNames from "classnames";

export class MainHeader extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    sidebarVisible: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Router>
        {this.props.sidebarVisible && <Sidebar />}
        <div
          className={classNames(
            "relative bg-gray-200 flex flex-col min-h-screen",
            {
              "md:ml-64": this.props.sidebarVisible,
            }
          )}
        >
          {/* <div className="mb-8">
            <Navbar />
          </div> */}
          <div className="flex-grow">
            {/* Content here */}
            {window.location.pathname === "/dashboard" ? (
              <Redirect to="/dashboard" />
            ) : (
              ""
            )}
            <Switch>
              <Route exact path="/" component={DashboardPage} />
              <Route path="/dashboard" component={DashboardPage} />
              <Route path="/categories" component={CategoryPage} />
              <Route path="/partners" component={PartnerPage} />
              <Route path="/users" component={UserPage} />
              <Route path="/vouchers" component={VoucherPage} />
              <Route
                path="/voucher-distributions"
                component={VoucherDistributionPage}
              />
              <Route path="/banners" component={BannerPage} />
              <Route path="/popup-banners" component={PopupBannerPage} />
              <Route path="/site-content" component={ContentPage} />
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebarVisible: state.general.sidebarVisible,
});
export default connect(mapStateToProps, { logout })(MainHeader);
