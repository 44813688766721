import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";
import { LOGOUT } from "./actions/types";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import Login from "./views/Login";
import MainHeader from "./components/common/MainHeader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetUserPassword from "./views/ResetUserPassword";

const App = () => {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  });
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Switch>
            <PublicRoute
              path="/reset-user-password/:token"
              component={ResetUserPassword}
            />
            <PublicRoute exact path="/login" component={Login} />
            <PrivateRoute path="/" component={MainHeader} />
          </Switch>
          <ToastContainer autoClose={5000} />
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
