import {
  GET_SITE_CONTENT,
  UPDATE_SITE_CONTENT,
  LOADING_SITE_CONTENT,
} from "../actions/types";

const initialState = {
  siteContent: null,
  isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_SITE_CONTENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SITE_CONTENT:
    case UPDATE_SITE_CONTENT:
      return {
        ...state,
        siteContent: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
