import {
  GET_USERS,
  SELECT_USER,
  LOADING_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  COUNT_PENDING_USERS,
} from "../actions/types";

const initialState = {
  users: [],
  pendingUsersCount: 0,
  pagination: null,
  isLoading: false,
  selectedUser: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: payload,
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) => {
          console.log(payload._id, user._id);
          if (payload._id !== user._id) {
            return user;
          }

          return {
            ...user,
            ...payload,
          };
        }),
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== payload),
      };
    case COUNT_PENDING_USERS:
      return {
        ...state,
        pendingUsersCount: payload,
      };
    default:
      return state;
  }
}
