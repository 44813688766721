import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fieldset, Button, Label } from "../components/common";
import { changePassword } from "../actions/auth";
import swal from "sweetalert";
import { toast } from "react-toastify";

export default function ChangePasswordForm() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onSubmit = (e) => {
    if (newPassword === confirmNewPassword) {
      swal({
        icon: "warning",
        title: "Are you sure?",
        text: "This will change the password.",
        buttons: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          dispatch(changePassword(user.email, oldPassword, newPassword));
        }
      });
    } else {
      toast.warn("New passwords do not match.");
    }
  };

  return (
    <Fieldset>
      <h1 className="text-sm font-extrabold text-gray-600">
        Change Your Password
      </h1>
      <p className="text-xs text-gray-400 mb-4">
        Update your credentials every time.
      </p>

      <div className="mb-2">
        <Label name="Old Password" />
        <input
          type="password"
          className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
          style={{ border: "1px solid #ccc" }}
          value={oldPassword}
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
        />
      </div>

      <div className="mb-2">
        <Label name="New Password" />
        <input
          type="password"
          className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
          style={{ border: "1px solid #ccc" }}
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
      </div>

      <div className="mb-2">
        <Label name="Confirm New Password" />
        <input
          type="password"
          className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
          style={{ border: "1px solid #ccc" }}
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value);
          }}
        />
      </div>

      <Button
        onClick={(e) => {
          onSubmit(e);
        }}
        success="true"
        text="Update Password"
      />
    </Fieldset>
  );
}
