import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Tab, Typography, Spinner } from "../components/common";
import swal from "sweetalert";
import {
  getAvailableUsers,
  getAssignedUsers,
  assignUsersToVoucher,
  removeUsersToVoucher,
} from "../actions/vouchers";
import moment from "moment";
import { usersColumns, userVoucherColumns } from "./TableColumns";
import {
  MinusCircleButton,
  PlusCircleButton,
} from "../components/common/icons/HeroIcons";

export default function VoucherUserForm({ onViewClose }) {
  const dispatch = useDispatch();
  const selectedVoucher = useSelector(
    (state) => state.vouchers.selectedVoucher
  );
  const [tab, setTab] = useState("Assigned");
  const assignedUsers = useSelector((state) => state.vouchers.assignedUsers);
  const availableUsers = useSelector((state) => state.vouchers.availableUsers);
  const isLoading = useSelector((state) => state.vouchers.isLoading);

  useEffect(() => {
    if (tab === "Assigned") {
      dispatch(getAssignedUsers(selectedVoucher._id));
    } else if (tab === "Available") {
      dispatch(getAvailableUsers(selectedVoucher._id));
    }
  }, [tab, dispatch, selectedVoucher]);

  return (
    <div>
      <Typography type="h1">Voucher Users</Typography>

      <div className="mb-4">
        <Tab>
          <Tab.Item
            name="Assigned"
            isActive={tab === "Assigned"}
            onTabClick={() => {
              setTab("Assigned");
            }}
          />

          <Tab.Item
            name="Available"
            isActive={tab === "Available"}
            onTabClick={() => {
              setTab("Available");
            }}
          />
        </Tab>
      </div>

      {tab === "Assigned" && (
        <>
          <Table
            columns={userVoucherColumns}
            data={
              <>
                {assignedUsers.map((assignedUser) => (
                  <tr
                    key={assignedUser._id}
                    className="hover:bg-gray-200 cursor-pointer"
                  >
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {!assignedUser.date_redeemed && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                            Pending Redeem
                          </span>
                        )}

                        {assignedUser.date_redeemed && (
                          <>
                            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                              Claimed
                            </span>
                            <br />
                            {moment(assignedUser.date_redeemed)
                              .format("DD/MM/Y")
                              .toString()}
                            <br />
                            {assignedUser.pin_used[0].pin} (
                            {assignedUser.pin_used[0].label})
                          </>
                        )}
                      </div>
                    </td>

                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {assignedUser.user.name}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {assignedUser.user.email}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {assignedUser.user.phone_number}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(assignedUser.user.birth_date)
                          .format("DD/MM/Y")
                          .toString()}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="flex flex-col text-gray-600 hover:text-gray-900"
                        onClick={() => {
                          swal({
                            title: "Remove user on voucher?",
                            text: "This will remove the assignment of user on the voucher, making the user unable to access the voucher. Are you sure?",
                            icon: "error",
                            buttons: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              dispatch(
                                removeUsersToVoucher(selectedVoucher._id, [
                                  assignedUser.user._id,
                                ])
                              );
                            }
                          });
                        }}
                      >
                        <MinusCircleButton title={"Remove"} />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
          {isLoading && <Spinner />}
        </>
      )}

      {tab === "Available" && (
        <>
          <Table
            columns={usersColumns}
            data={
              <>
                {availableUsers.map((user) => (
                  <tr
                    key={user._id}
                    className="hover:bg-gray-200 cursor-pointer"
                  >
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.status === "Pending" && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                            Pending
                          </span>
                        )}

                        {user.status === "Approved" && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                            Approved
                          </span>
                        )}

                        {user.status === "Disabled" && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                            Disabled
                          </span>
                        )}
                      </div>
                    </td>

                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.name}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.email}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.phone_number}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(user.date).format("DD/MM/Y").toString()}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(user.date)
                          .add(1, "year")
                          .format("DD/MM/Y")
                          .toString()}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {moment(user.birth_date).format("DD/MM/Y").toString()}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.zodiac_sign}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="flex flex-col text-gray-600 hover:text-gray-900"
                        onClick={() => {
                          dispatch(
                            assignUsersToVoucher(selectedVoucher._id, [
                              user._id,
                            ])
                          );
                        }}
                      >
                        <PlusCircleButton title={"Add"} />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
          {isLoading && <Spinner />}
        </>
      )}

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            onViewClose && onViewClose();
          }}
        />
      </div>
    </div>
  );
}
